<h2 mat-dialog-title>{{companyForm.controls.id.value ? ('companies_company-modification' | translate) : ('companies_new-company-recording'|translate)}}</h2>
<mat-dialog-content>
  <form [formGroup]="companyForm">
    <mat-form-field appearance="outline">
      <mat-label>{{'companies_eu-tax-number' | translate}}</mat-label>
      <input matInput formControlName="tax_number" required>
      <mat-error *ngIf="companyForm.controls.tax_number.hasError('required')">
        {{'error_message_tax_number_cannot_be_empty' | translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'companies_mail-address' | translate}}</mat-label>
      <input matInput formControlName="email_address" required>
      <mat-error *ngIf="companyForm.controls.email_address.hasError('required')">
        {{'error_message_email_address_cannot_be_empty' | translate}}
      </mat-error>
      <mat-error *ngIf="companyForm.controls.email_address.hasError('pattern')">
        {{'error_message_email_address_inappropriate_format' | translate}}
    </mat-error>
    </mat-form-field>
    <mat-dialog-actions [style.justify-content]="'space-around'">
      <button mat-button (click)="retrieveCompanyData()" class="positiveButton" [disabled]="(!companyForm.controls.tax_number.valid || !companyForm.controls.email_address.valid) && !companyForm.controls.id.value">{{'companies_company-data-retrieval' | translate}}</button>
      <button mat-button (click)="setManualUpload()" class="negativeButton" [disabled]="(!companyForm.controls.tax_number.valid || !companyForm.controls.email_address.valid) && !companyForm.controls.id.value">{{'companies_manual-upload' | translate}}</button>
    </mat-dialog-actions>

    <mat-form-field appearance="outline">
      <mat-label>{{'companies_company-registration-number' | translate}}</mat-label>
      <input matInput formControlName="company_registration_number" required>
      <mat-error *ngIf="companyForm.controls.company_registration_number.hasError('required')">
        {{'error_message_registration_number_cannot_be_empty' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{'companies_tax-number' | translate}}</mat-label>
      <input matInput formControlName="hu_tax_number" required>
      <mat-error *ngIf="companyForm.controls.tax_number.hasError('required')">
        {{'error_message_tax_number_cannot_be_empty' | translate}}
      </mat-error>
    </mat-form-field>

    <div class="formInputContainer">
      <mat-form-field appearance="outline">
        <mat-label>{{'companies_company-name' | translate}}</mat-label>
        <input matInput formControlName="long_name" required>
        <mat-error *ngIf="companyForm.controls.long_name.hasError('required')">
          {{'error_message_company_name_cannot_be_empty' | translate}}
        </mat-error>
      </mat-form-field>
      <div [style.padding]="'1em'" [style.min-width]="'50px'" [style.white-space]="'nowrap'">{{companyNameHistory.history.length > 0 ? (companyNameHistory.history[companyNameHistory.history.length-1].validity ? createDateString(companyNameHistory?.history[companyNameHistory.history.length-1].validity) : "") : ""}}</div>
      <div [style.padding]="'1em'">
        <mat-dialog-actions [style.justify-content]="'space-around'">
          <!--TODO ha manuális feltöltés után van Érvényesség adat vagy új cég - Történet, egyébként Új Érvényesség ?-->
          <button mat-button (click)="showHistory(companyNameHistory, false)" class="neutralButton" [disabled]="!enabledFormFilling">{{companyNameHistory.history.length > 0 ? ('companies_history' | translate) : ('companies_history-new-validity' | translate)}}</button>
        </mat-dialog-actions>
      </div>
    </div>
    <div class="formInputContainer">
      <app-select-search [selectionItems]="currencyList" [selectionType]="'singleSelect'" [placeholder]="'companies_company-accounting-currency' | translate" [selectCtrl]="companyForm.controls.currency_iso_code" [required]="false" [showClearButton]="true" [style.width]="'100%'">
      </app-select-search>
      
      <div [style.padding]="'1em'" [style.min-width]="'50px'" [style.white-space]="'nowrap'">{{currencyHistory.history.length > 0 ? (currencyHistory?.history[currencyHistory.history.length-1].validity ? createDateString(currencyHistory?.history[currencyHistory.history.length-1].validity):"") :""}}</div>
      <div [style.padding]="'1em'">
        <!--mat-dialog-actions [style.justify-content]="'space-around'">
          <button mat-button (click)="showHistory(currencyHistory, false)" class="neutralButton" [disabled]="!enabledFormFilling">{{currencyHistory.history.length > 0 ? ('companies_history' | translate) : ('companies_history-new-validity' | translate)}}</button>
        </mat-dialog-actions-->
      </div>
    </div>

    <!-- <div class="formInputContainer">
      <mat-form-field appearance="outline">
        <mat-label>{{'companies_short-name' | translate}}</mat-label>
        <input matInput formControlName="short_name">
      </mat-form-field>
      <div [style.padding]="'1em'" [style.min-width]="'50px'" [style.white-space]="'nowrap'">{{shortNameHistory.history.length > 0 ? (shortNameHistory?.history[shortNameHistory.history.length-1].validity ? createDateString(shortNameHistory?.history[shortNameHistory.history.length-1].validity) : "") :""}}</div>
      <div [style.padding]="'1em'">
        <mat-dialog-actions [style.justify-content]="'space-around'">
          <button mat-button (click)="showHistory(shortNameHistory, false)" class="neutralButton" [disabled]="!enabledFormFilling">{{shortNameHistory.history.length > 0 ? ('companies_history' | translate) : "Új érvényesség"}}</button>
        </mat-dialog-actions>
      </div>
    </div> -->

    <div class="formInputContainer" *ngFor="let element of countryDims">
      <mat-form-field appearance="outline">
        <!-- mat-label>{{'companies_main-activity' | translate}}</mat-label-->
        <mat-label>{{element.dim_code | translate}}</mat-label>
        <input matInput [required]="element.dim_status_sid=='DIM_STAT_REQUIRED'" [ngModelOptions]="{standalone: true}" [(ngModel)]="valueSet[element.dim_code]">
      </mat-form-field>
      <div [style.padding]="'1em'" [style.min-width]="'50px'" [style.white-space]="'nowrap'">{{mainActivityHistory.history.length >0 ? (mainActivityHistory?.history[mainActivityHistory.history.length-1].validity ? createDateString(mainActivityHistory?.history[mainActivityHistory.history.length-1].validity) : "") :""}}</div>
      <div [style.padding]="'1em'">
        <!--mat-dialog-actions [style.justify-content]="'space-around'">
          <button mat-button (click)="showHistory(mainActivityHistory, false)" class="neutralButton" [disabled]="!enabledFormFilling">{{mainActivityHistory.history.length > 0 ? ('companies_history' | translate) : ('companies_history-new-validity' | translate)}}</button>
        </mat-dialog-actions-->
      </div>
    </div>

    <!-- div class="formInputContainer">
      <mat-form-field appearance="outline">
        <mat-label>{{'companies_activity-start' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker"  [placeholder]="'dateformat-placeholder' | translate" [imask]="{mask: 'dateformat-mask' | translate}" #input_valid_from>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <div [style.padding]="'1em'" [style.min-width]="'50px'" [style.white-space]="'nowrap'">{{activityStartHistory.history.length > 0 ? (activityStartHistory?.history[activityStartHistory.history.length-1].validity ? createDateString(activityStartHistory?.history[activityStartHistory.history.length-1].validity) :"") : ""}}</div>
    </div -->
    <div class="formInputContainer">
        <app-select-search [selectionItems]="bank_accounts" [selectionType]="'singleSelect'" [placeholder]="'companies_primary-bank-account' | translate" [selectCtrl]="companyForm.controls.primary_bank_account_account" [required]="false" [showClearButton]="true" [style.width]="'100%'">
        </app-select-search>
      <div [style.padding]="'1em'" [style.width]="'50px'"></div>
      <div [style.padding]="'1em'">
        <mat-dialog-actions [style.justify-content]="'space-around'">
          <button mat-button (click)="showAccounts()" class="neutralButton" [disabled]="!enabledFormFilling">{{'companies_more' | translate}}</button>
        </mat-dialog-actions>
      </div>
    </div>
    <div class="formInputContainer">
      <app-select-search [selectionItems]="representatives" [selectionType]="'singleSelect'" [placeholder]="'companies_primary-company-representative' | translate" [selectCtrl]="companyForm.controls.primary_representative_tax_number" [required]="false" [showClearButton]="true" [style.width]="'100%'">
      </app-select-search>
      <!--<app-select-search [selectionItems]="representatives" [selectionType]="'singleSelect'" [placeholder]="'companies_primary-company-representative' | translate" [selectCtrl]="companyForm.controls.primary_representative_tax_number"></app-select-search>-->
      <div [style.padding]="'1em'" [style.width]="'50px'"></div>
      <div [style.padding]="'1em'">
        <mat-dialog-actions [style.justify-content]="'space-around'">
          <button mat-button (click)="showRepresentatives()" class="neutralButton" [disabled]="!enabledFormFilling">{{'companies_more' | translate}}</button>
        </mat-dialog-actions>
      </div>
    </div>
    <app-select-search [selectionItems]="signatoryAuditorSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'companies_sign-right-auditor' | translate" [selectCtrl]="companyForm.controls.signatoryAuditor" [required]="true" [showClearButton]="true" [disabled]="this.companyForm.get('signatoryAuditor').disabled">
    </app-select-search>
    <app-select-search [selectionItems]="decisionSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'companies_acceptance-decision-result' | translate" [selectCtrl]="companyForm.controls.decision | translate" [required]="false" *ngIf="enableDecisionInput" [disabled]="this.companyForm.get('decision').disabled">
    </app-select-search>
<!--    <app-select-search [selectionItems]="statusSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'companies_company-status' | translate" [selectCtrl]="companyForm.controls.customer_status_sid" [required]="false" *ngIf="companyForm.controls.customer_status_sid.value == 'CUS_STAT_ACTIVE' ">-->
        <app-select-search [selectionItems]="statusSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'companies_company-status' | translate" [selectCtrl]="companyForm.controls.customer_status_sid" [required]="false" >
    </app-select-search>
  </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
  <button mat-button (click)="saveCompany()" class="positiveButton" [disabled]="!enabledFormFilling">{{'companies_record' | translate}}
  </button>
  <button mat-button (click)="saveCompany(true)" class="neutralButton" [disabled]="!companyForm.controls.tax_number.valid || !companyForm.controls.email_address.valid" *ngIf="!companyForm.controls.customer_status_sid.value || companyForm.controls.customer_status_sid.value == 'CUS_STAT_PENDING'">{{'companies_leave-append' | translate}}</button>
  <button mat-button (click)="reject()" class="negativeButton">{{'companies_cancel' | translate}}</button>
</mat-dialog-actions>
