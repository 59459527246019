import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    Component, OnInit, ViewChild, ViewChildren, ChangeDetectorRef,
    AfterContentInit, ElementRef
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { OkDialogComponent } from 'src/app/components/ok-dialog/ok-dialog.component';
import { PaginatedAnswer } from 'src/app/data/response';
import { Template } from 'src/app/data/template';
import { CredentialsService } from 'src/app/services/credentials.service';
import { ProgressService } from 'src/app/services/progress.service';
import { environment } from 'src/environments/environment';
import { Contract } from '../../../../data/company';
import { TemplateService } from '../../../../services/template.service';
import add from 'date-fns/add'
import { format } from 'date-fns';
import { CompanyService } from '../../../../services/company.service';
import {DateAdapter} from "@angular/material/core";
import {DateFormatPipe} from "../../../../pipes/date-format.pipe";
import {DateParsePipe} from "../../../../pipes/date-parse.pipe";
import { FileUploaderEditorComponent } from 'src/app/components/file-system/file-uploader-editor/file-uploader-editor.component';



@Component({
  selector: 'app-contract-editor',
  templateUrl: './contract-editor.component.html',
  styleUrls: ['./contract-editor.component.less']
})
export class ContractEditorComponent implements AfterContentInit, OnInit {

  maxday = new Date(new Date(2999,12,31).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0];
  minValidFrom;
  hasFile = false;
  validationErrors: string[] = [];

  contractForm: FormGroup = this.formBuilder.group({
    id: null,
    contract_type_sid: [null, [Validators.required]],
    signed_at: [null],
    signed_by_id: [null, [Validators.required]],
    company_signer_id: [[], [Validators.required]],
    valid_to: [null],
    valid_from: [null],
    business_year_start: [null],
    business_year_end: [null],
    is_workflow_integrated: false,
    is_digitaly_signed: false,
    subtemplate_id: [null],
    templateType: [null, [Validators.required]],
//    templates: [[], [Validators.required]],
    templates: [[], null],
    contractResult: [null, [Validators.required]],
    contract_status_sid: null,
    contract_code: null,
    customer_id: null,
    audit_fee: [null, [Validators.required]],
    currency_iso_code: [null, [Validators.required]],
    email_locale: 'hu', //this.credService.userData.locale // fixMe: hiányzik!
    is_modified: [null, []],
    usertext: this.formBuilder.array([])
  });

  usertextForm: any;

  _contract: Contract;
  set contract(value) {
    this._contract = value;
    this.contractForm.patchValue(value);
    // console.log(value);
    this.contractForm.get("signed_by_id").setValue(value.signed_by);
    this.contractForm.get("company_signer_id").setValue(value.company_signer_id);
    if (this.contractForm.get("contract_status_sid").value == "CON_STAT_ACTIVE"){
      this.contractForm.get("contractResult").setValue("CON_STAT_ACTIVE")
    }
    if (this.contractForm.get("contract_status_sid").value == "CON_STAT_REJETED"){
      this.contractForm.get("contractResult").setValue("CON_STAT_REJETED")
    }
  }

  _usertexts: any;
  get usertexts() {return this._usertexts};
  set usertexts(value:any){
    this._usertexts = value;
  }

  _customerId: any;
  get customerId() { return this._customerId };
  set customerId(value: any) {
    this._customerId = value;
    this.contractForm.get("customer_id").setValue(value)
  }

  _currencyISOCode: any;
  get currencyISOCode() { return this._currencyISOCode };
  set currencyISOCode(value: any) {
    this._currencyISOCode = value;
    this.contractForm.get("currency_iso_code").setValue(value)
  }

  _typeSelectionItems: any;
  get typeSelectionItems() { return this._typeSelectionItems };
  set typeSelectionItems(value: any) {
    this._typeSelectionItems = value;
  }

  _editable: any;
  get editable() { return this._editable };
  set editable(value: any) {
    this._editable = value;
  }

  _signatoryAuditorSelectionItems: any;
  get signatoryAuditorSelectionItems() { return this._signatoryAuditorSelectionItems };
  set signatoryAuditorSelectionItems(value: any) {
    this._signatoryAuditorSelectionItems = value;
  }

  _rightToSignSelectionItems: any;
  get rightToSignSelectionItems() { return this._rightToSignSelectionItems };
  set rightToSignSelectionItems(value: any) {
    this._rightToSignSelectionItems = value;
  }

  _contractResultSelectionItems: any;
  get contractResultSelectionItems() { return this._contractResultSelectionItems };
  set contractResultSelectionItems(value: any) {
    this._contractResultSelectionItems = value;
  }

  @ViewChildren('templateCheckbox') templateCheckboxes;
  @ViewChildren('tableRowDefault') tableRowsDefault;
  @ViewChildren('tableRowModified') tableRowsModified;
  @ViewChildren('is_esign') isEsign;

  templates = [];
  subtypeList = [];
  templateTypeSelectionItems = [];
  templateList: Template[] = [];
  currencyList = [];
  disabled=true;
  templateSelected:boolean=false;
  datePickerChange:boolean=false;


  
  
  originalFormData: Contract;

  constructor(private elementRef:ElementRef, private pipeParse: DateParsePipe, private pipeFormat: DateFormatPipe, private _adapter: DateAdapter<any>, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ContractEditorComponent>, private dialogService: MatDialog, private ref: ChangeDetectorRef, private httpClient: HttpClient, private snackBar: MatSnackBar, private progressService: ProgressService, private translateService: TranslateService, private templateService: TemplateService, private credService: CredentialsService, private companyService: CompanyService) {
      dialogRef.disableClose = true;
  }

  get templatesIsDisabled() {
    return (this.contractForm.get("is_workflow_integrated").value || this.contractForm.get("is_digitaly_signed").value) as Boolean;
  }

    @ViewChild("input_valid_from") inputValidFrom: ElementRef;
    @ViewChild("input_valid_to") inputValidTo: ElementRef;
    @ViewChild("input_businessYear_from") inputBusinessYearFrom: ElementRef;
    @ViewChild("input_businessYear_to") inputBusinessYearTo: ElementRef;
    @ViewChild("input_signed_at") inputSignedAt: ElementRef;

  ngAfterContentInit(): void{
      this._adapter.setLocale(localStorage.getItem("language") == "hu_HU"?'hu':'en');
 }


  ngOnInit(): void {

    this.loadCurrencies();

    // console.log("Signatory");
    // console.log(this.signatoryAuditorSelectionItems);
    if (!this.contractForm.get("id").value) {
      this.contractForm.get("signed_by_id").setValue(this.signatoryAuditorSelectionItems[0].value);
    }

    // console.log("Logged user");
    // console.log(this.credService.userData.id);

    // if (
    //     this.signatoryAuditorSelectionItems.find(
    //         (signer) => signer.value == this.credService.userData.id
    //     ) &&
    //     !this.contractForm.get("id").value  // ha új szerződés van
    // ) {
    //   this.contractForm.get("signed_by_id").setValue(this.credService.userData.id);  // beállítjuk a user-t
    // }

    if (this.rightToSignSelectionItems.find((signer)=> signer.value == this.credService.userData.id) && !this.contractForm.get("id").value){
      this.contractForm.get("company_signer_id").setValue(this.credService.userData.id)
    }



    //TODO
    this.templateService.listTemplates(CredentialsService.role).subscribe((response)=>{
      this.templates = response.payload;
      for(let i=0;i<this.templates.length;i++){
        this.templates[i]['path']="/"+this.templates[i].template_code+"/"+this.templates[i].subtemplate_id+"/"+this.templates[i].locale;
      }
      //console.log(this.templates);
      let items = [];
      response.payload.forEach((template: any) => {
        if (template.childs.find((child) => child.childs.length > 0)){
          items.push({name: template.template_code, value: template.template_code})
        }
      })
//      this.templateTypeSelectionItems = items;
    })

     this.templateService.listSubTemplates(CredentialsService.role).subscribe((response)=> {
//     this.templates = response.payload;
       let items = [];
       response.payload.content.forEach((template: any) => {
         if (template.is_active) {
           items.push({name: template.caption, value: template.id})
         }
       })
       this.templateTypeSelectionItems = items;
     });

    this.contractForm.controls.templateType.valueChanges.subscribe((value)=>{
      this.templateList = [];
      if (value){
        this.templates.forEach(templateSingle => {
          if(templateSingle.template_code == "TEMPLATE_CODE_KVVSZ" || templateSingle.template_code == "TEMPLATE_CODE_ELFNY" || templateSingle.template_code == "TEMPLATE_CODE_PMTNY"  ){
            templateSingle.childs.forEach(childSingle=>{
              if(childSingle.path.split("/")[2]==value){
                childSingle.childs.forEach(subChild=>[this.templateList.push(subChild)])
              }
            })
          }
        });
        

        /*let selectedTemplateByType = this.templates.find(template => template.template_code == "TEMPLATE_CODE_KONSZSZ")
        console.log("XXXX ---> "+value);
        console.log(this.templates);
        console.log(selectedTemplateByType);
        if (selectedTemplateByType.childs && selectedTemplateByType.childs.length > 0) {
          selectedTemplateByType.childs.forEach((subChild: any) => {
            if (subChild.childs && subChild.childs.length > 0) {
              subChild.childs.forEach((local: any) => {
                this.templateList.push(local);
              })
            } 
          })
        }*/
      }

      setTimeout(()=>{
        this.tableRowsDefault?.forEach((row, i) => {
          row.nativeElement.classList.add(this.calculateTableStripe(this.templateList, i));
        });

        this.tableRowsModified?.forEach((row, i) => {
          row.nativeElement.classList.add(this.calculateTableStripe(this._contract?.templates, i));
        });

      },0)
      
    })

    this.contractForm.controls.signed_at.valueChanges.subscribe((value)=>{
      this.minValidFrom = format(add(new Date(value), {days: 1}), 'yyyy-MM-dd');
      if (new Date(this.contractForm.controls.signed_at.value).getTime() >= new Date(this.contractForm.controls.valid_from.value).getTime()){
        this.contractForm.controls.valid_from.setErrors({'minDate': true})
      }
      else{
        setTimeout(() =>{
         this.contractForm.controls.valid_from.setErrors(null)
        })
      }
    })

    this.templateService.getSubtemplates(CredentialsService.role).then((response) => {
      response.payload.content.forEach((subtype)=>{
        if (subtype.is_active){
          this.subtypeList.push({value: subtype.id, name: subtype.caption})
        }
      })
    });  

    if(!this._contract){
     this.contractForm.get("contract_type_sid").setValue("CON_TYP_YEARLY")
     this.contractForm.get("signed_at").setValue(new Date(new Date().getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0]);
     //this.contractForm.get("valid_to").setValue(new Date(new Date(new Date().getFullYear(), 11, 31).getTime() - new Date(new Date().getFullYear(), 11, 31).getTimezoneOffset()*60*1000).toISOString().split("T")[0]);
      this.contractForm.get("templateType").setValue(2);
      if(this.usertexts){
        this.usertexts.payload.forEach((field) => {
            // console.log(field);
            this.addFormField(field);
          })
        } 
      setTimeout(()=>{
        //TODO
        if (this.templateTypeSelectionItems.length > 0){
        this.contractForm.get("is_workflow_integrated").setValue(true)
        this.contractForm.get("templates").enable();
        this.contractForm.get("templateType").enable();
        }else{
        this.contractForm.get("is_workflow_integrated").setValue(false)
        this.contractForm.get("templates").disable();
        this.contractForm.get("templateType").disable();
        }  
      })
 
    }
    else{
      this.templateSelected = true;
      this.originalFormData = this._contract;
      this.getExistingUserTexts();
      this.createRightToSelectionItems();
      this.contractForm.get("signed_at").setValue(new Date(new Date(this._contract.signed_at * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0]);
      this.contractForm.get("valid_to").setValue(new Date(new Date(this._contract.valid_to * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0]);
      this.contractForm.get("valid_from").setValue(new Date(new Date(this._contract.valid_from * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0]);
      this.contractForm.get("business_year_start").setValue(new Date(new Date(this._contract.business_year_start * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0]);
      this.contractForm.get("business_year_end").setValue(new Date(new Date(this._contract.business_year_end * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0]);

    }
    

    if (!this.contractForm.get('is_workflow_integrated').value){
      this.contractForm.get("templates").disable();
      this.contractForm.get("templateType").disable();
    }

    if (!this._contract || this._contract?.is_digitaly_signed || this._contract?.contract_status_sid != 'CON_STAT_SIGNING'){
      this.contractForm.get("contractResult").disable();
    }

    if (this._contract && this._contract?.contract_status_sid != 'CON_STAT_PENDING'){
      this.contractForm.get("contract_type_sid").disable();
      this.contractForm.get("templates").disable();
      this.contractForm.get("templateType").disable();
      this.contractForm.get("is_workflow_integrated").disable();
    }

    this.contractForm.get("contract_type_sid").valueChanges.subscribe(selectedValue => {
      if (selectedValue == "CON_TYP_OTHER"){
        this.contractForm.get("is_workflow_integrated").setValue(false);
        this.contractForm.get("is_workflow_integrated").disable();
        this.contractForm.get("templates").disable();
        this.contractForm.get("templateType").disable();
      }
      else{
        if (!this._contract){
          this.contractForm.get("is_workflow_integrated").enable();
          this.contractForm.get("templates").enable();
          this.contractForm.get("templateType").enable();
        }
      }
    })
    
  }

  toBinary(string) {
    const codeUnits = Uint16Array.from(
      { length: string.length },
      (element, index) => string.charCodeAt(index)
    );
    const charCodes = new Uint8Array(codeUnits.buffer);
  
    let result = "";
    charCodes.forEach((char) => {
      result += String.fromCharCode(char);
    });
    return result;
  }

  fromBinary(binary) {
    const decoder = new TextDecoder();
    const bytes = Uint8Array.from({ length: binary.length }, (element, index) =>
      binary.charCodeAt(index)
    );
    const charCodes = new Uint16Array(bytes.buffer);
    let result = "";
    charCodes.forEach((char) => {
      result += String.fromCharCode(char);
    });
    return result;
  }
  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
    }))
    }
  b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent((str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  newFormField(params?){
    // console.log(params.content);
    // console.log(this.fromBinary(atob(params.content)));
    return this.formBuilder.group({
      template_code: params.template_code,
      usertext_code: params.usertext_code,
      content: this.fromBinary(atob((params.content))),
      locale: localStorage.getItem("language") as string
    })
  }

  getUserTexts(): FormArray {
    return this.contractForm.get("usertext") as FormArray;
  }

  disableUserTexts(){
    (this.contractForm.get("usertext") as FormArray).disable();
  }

  addFormField(params){
    this.getUserTexts().push(this.newFormField(params));
  }

  createRightToSelectionItems(){
    this.contractForm.get("company_signer_id").value.forEach(signer => {
      // console.log(signer);
    })
  }

  loadCurrencies(){
    this.companyService.getCurrencies(CredentialsService.role).subscribe((data: any) => {
      data.forEach((item: any)=>{
        item.value = item.iso_code
      })
      this.currencyList = data;
    });
  }

  ngAfterViewInit(){

    let templates = this.contractForm.get("templates").value;
    templates.forEach(template => {
      if(template.has_uploaded){
        this.hasFile = true;
      }
    })
    

    if(!this.editable && this._contract){
      this.contractForm.disable();
    }
    if (this._contract){
      this.contractForm.get('templateType').setValue(this._contract.subtemplate_id);
      if (this.contractForm.get('is_workflow_integrated').value && this._contract?.contract_status_sid == 'CON_STAT_PENDING'){
        let foundtemplateIndex;
        for (let template of this._contract.templates){
          this.selectTemplate(template)
          foundtemplateIndex = this.templateList.findIndex(x => x.id === template.id);
            this.templateCheckboxes._results[foundtemplateIndex].checked = true;
        }
      }
    }

    if(this._contract) {
        this.inputValidFrom.nativeElement.value = this.pipeFormat.transform(this._contract.valid_from * 1000);
        this.inputValidTo.nativeElement.value = this.pipeFormat.transform(this._contract.valid_to * 1000);
        this.inputSignedAt.nativeElement.value = this.pipeFormat.transform(this._contract.signed_at * 1000);
        this.inputBusinessYearFrom.nativeElement.value = this.pipeFormat.transform(this._contract.business_year_start * 1000);
        this.inputBusinessYearTo.nativeElement.value = this.pipeFormat.transform(this._contract.business_year_end * 1000);
    }
      this.ref.detectChanges();
      
  }

  async getExistingUserTexts(){
    let body = {
      customer_id: this.customerId,
      contract_id: this._contract.id 
    }

    this.companyService.getUserTexts(body).subscribe(data => {
      // console.log(data);
      this.usertexts = data;
      this.usertexts.payload.forEach((field) => {
        this.addFormField(field);
      });
      if (!this._editable) {
        this.disableUserTexts();
      }
    })
  }

  getSubtype(subtemplate_code) {
    return this.subtypeList.find(subtype => subtype.value === +subtemplate_code)?.name;
  }

  tableStripe = "tableStripeWhite";
  calculateTableStripe(list, i: number){
    let template1 = list[i-1];
    let template2 = list[i];

    if (!template1){
      return this.tableStripe;
    }
    if (template1?.subtemplate_code == template2.subtemplate_code){
      return this.tableStripe;
    }
    else{
      if (this.tableStripe == "tableStripeWhite"){
        this.tableStripe = "tableStripeGrey"
        return this.tableStripe;
      }
      else {
        this.tableStripe = "tableStripeWhite"
        return this.tableStripe;
      }
    }    
  }

  selectTemplate(template){
    
    this.templateCheckboxes.forEach(tbox => {
      let attr = tbox.name.split('/');
      if(attr[0]==template.template_code && attr[2]!= template.locale ){
        tbox.checked = false;
      }
    })
    this.contractForm.get("templates").setValue([]);
    this.templateCheckboxes.forEach(tbox => {
      let attr = tbox.name.split('/');
      let esign = this.isEsign.checked ? true : false;
      this.templateSelected = true;
      if(tbox.checked){
        this.contractForm.get("templates").value.push({locale: attr[2], subtemplate_id: +template.subtemplate_code, template_code: attr[0], path: '/'+attr[0]+'/'+template.subtemplate_code+'/'+attr[2], is_esign: esign});

      }
    });
    this.contractForm.get("templates").updateValueAndValidity();
    if(this.contractForm.get("templates").value.length === 0){
      this.templateSelected = false;
    }
  }

  handleFileContent(response, filename){
  
      var byteCharacters = atob(response.payload.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let newFilename = response.payload.name;
      var byteArray = new Uint8Array(byteNumbers);
      var file = new File([byteArray], newFilename, {type: response.payload.mime_type});
//      var fileURL = URL.createObjectURL(file);
//      window.open(fileURL);
      
      var link = document.createElement("a");
      var fileURL = URL.createObjectURL(file);        
      link.href = fileURL;
      link.download = newFilename;
      link.click();       
  }
  openTemplate(template) {
    // console.log("Template:")
    // console.log(template)
      if (!this._contract || this._contract.contract_status_sid == "CON_STAT_PENDING") {
          this.templateService.getTemplate(template).then(response => {
              this.handleFileContent(response, `${template.template_code}-${this.getSubtype(template.subtemplate_code)}-${template.locale}`);
          })
      }else{
          this.templateService.getTemplateContract(template, this._contract.id).then(response => {
              this.handleFileContent(response, `${template.template_code}-${this.getSubtype(template.subtemplate_code)}-${template.locale}`);
          })
      }
  }

//új oszlop a dokumentum státuszának -> 3 állapot (aláírva, elutasítva, aláírásra vár)

  saveContract(isPending: boolean){
    //if(this.templateSelected === false){return;}
    // console.log(this.contractForm.valid);
    // console.log("Templates");
    // console.log(this.templateList);
    if (this.contractForm.valid && this.checkForm()) {
      if((this.contractForm.get("is_workflow_integrated").value || this.contractForm.get("is_digitaly_signed").value) && !isPending){
        const dialog = this.dialogService.open(OkDialogComponent);
        this.translateService.get('companies_check-generated-doc').subscribe((value)=>dialog.componentInstance.title = value)
        this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
        this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
        let form = JSON.parse(JSON.stringify(this.contractForm.getRawValue()));
        delete form.templateType;
        delete form.contractResult;
        form.signed_at = new Date(form.signed_at).getTime() / 1000;
        form.valid_from = new Date(this.pipeParse.transform(this.inputValidFrom.nativeElement.value)).getTime() / 1000;
        form.valid_to = new Date(this.pipeParse.transform(this.inputValidTo.nativeElement.value)).getTime() / 1000;
        form.business_year_start = new Date(this.pipeParse.transform(this.inputBusinessYearFrom.nativeElement.value)).getTime() / 1000;
        form.business_year_end = new Date(this.pipeParse.transform(this.inputBusinessYearTo.nativeElement.value)).getTime() / 1000;
        form.signed_at = new Date(this.pipeParse.transform(this.inputSignedAt.nativeElement.value)).getTime() / 1000;
        form.workflow_integrated_template_code = "TEMPLATE_CODE_ELFNY";
        form.templates.forEach(template => {
          template.template_code = template.path.split('/')[1];
        });
        form.subtemplate_id = this.contractForm.controls.templateType.value*1;

        dialog.afterOpened().subscribe(() => {
          form.is_preview=true;
          if (this.contractForm.controls.id.value){
            if (this.contractForm.controls.contractResult.value){
              form.contract_status_sid = this.contractForm.controls.contractResult.value;
            }
            let modifiedUserTexts = [];
              form.usertext.forEach(field => {
                if(field.content){
                  let text = {template_code: field.template_code, usertext_code: field.usertext_code, locale: field.locale, content: btoa(this.toBinary(field.content))}
                  modifiedUserTexts.push(text);
                }
              })
              form.usertext = modifiedUserTexts;
              // console.log(form);
            this.modifyBackendContract(form, true).subscribe((response) => {
              this.handleFileContent(response, "Elfogadói nyilatkozat előnézet");
            });
          }else{
            delete form.id;
            if (!isPending){
              if (form.is_digitaly_signed){
                form.contract_status_sid = 'CON_STAT_SIGNING'; 
              }
              else{
                form.contract_status_sid = 'CON_STAT_WAITING'; 
              }
            }
            this.saveBackendContract(form, true).subscribe((response) => {
              this.handleFileContent(response, "Elfogadói nyilatkozat előnézet");
              form.contract_code = response.payload.contract_code;
              form.contract_id = response.payload.contract_id;
            });
          }
        })
        dialog.afterClosed().subscribe(response => {
          if (response) {
            if (isPending){
              this.contractForm.get("contract_status_sid").setValue('CON_STAT_PENDING');
            }
            form.is_preview = false;          
            if (this.contractForm.controls.id.value){
              if (this.contractForm.controls.contractResult.value){
                form.contract_status_sid = this.contractForm.controls.contractResult.value;
              }
              this.modifyBackendContract(form, false).subscribe((response) => {
                this.dialogRef.close(response);
              });
            }else{
              delete form.id;
              if (!isPending){
                if (form.is_digitaly_signed){
                  form.contract_status_sid = 'CON_STAT_SIGNING'; 
                }
                else{
                  form.contract_status_sid = 'CON_STAT_WAITING'; 
                }
                
              }
              // console.log(form);
              this.saveBackendContract(form, false).subscribe((response) => {
                this.dialogRef.close(response);
              });
            }
          }else{

          }
        })
      } else {
        if (isPending){
          this.contractForm.get("contract_status_sid").setValue('CON_STAT_PENDING');
        }
        let form = JSON.parse(JSON.stringify(this.contractForm.getRawValue()));
        // console.log("Form:");
        // console.log(form);
        delete form.templateType;
        delete form.contractResult;
        form.signed_at = new Date(form.signed_at).getTime() / 1000;
          form.valid_from = new Date(this.pipeParse.transform(this.inputValidFrom.nativeElement.value)).getTime() / 1000;
          form.valid_to = new Date(this.pipeParse.transform(this.inputValidTo.nativeElement.value)).getTime() / 1000;
          form.business_year_start = new Date(this.pipeParse.transform(this.inputBusinessYearFrom.nativeElement.value)).getTime() / 1000;
          form.business_year_end = new Date(this.pipeParse.transform(this.inputBusinessYearTo.nativeElement.value)).getTime() / 1000;
          form.signed_at = new Date(this.pipeParse.transform(this.inputSignedAt.nativeElement.value)).getTime() / 1000;
//        form.valid_to = new Date(form.valid_to).getTime() / 1000;
//        form.valid_from = new Date(form.valid_from).getTime() / 1000;
//        form.template_code = form.path.split('/')[1];
        form.templates.forEach(template => {
          if(this.contractForm.get("is_digitaly_signed").value){
            template.is_esign = true;
          }
          template.template_code = template.path.split('/')[1];
        });
        // console.log("Form2:");
        // console.log(form);
        let modifiedUserTexts = [];
              form.usertext.forEach(field => {
                if(field.content){
                  let text = {template_code: field.template_code, usertext_code: field.usertext_code, locale: field.locale, content: btoa(this.toBinary(field.content))}
                  modifiedUserTexts.push(text);
                }
              })
              form.usertext = modifiedUserTexts;

        form.subtemplate_id = this.contractForm.controls.templateType.value*1;
        if (form.templates.length > 0) {
          if (this.contractForm.controls.id.value) {
            if (this.contractForm.controls.contractResult.value) {
              form.contract_status_sid = this.contractForm.controls.contractResult.value;
            }
            this.modifyBackendContract(form, false).subscribe((response) => {
              this.dialogRef.close(response);
            });
          } else {
            delete form.id;
            if (!isPending) {
              form.contract_status_sid = 'CON_STAT_ACTIVE';
            }
            this.saveBackendContract(form, false).subscribe((response) => {
              this.dialogRef.close(response);
            });
          }
        } else {
            this.translateService.get('no-template-selected').subscribe((value) =>
                this.snackBar.open(value, null, {duration: 3000,panelClass: 'error-snackbar' }));
        }
      }
      
    } else {
      this.contractForm.markAllAsTouched();
    }
  }

  saveBackendContract(body, preview) {
    const task = this.progressService.addTask(this.constructor.name + '_save_contract');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    // console.log(body);
    if(preview){
      return this.httpClient.post<any>(environment.serverEndpoint + '/user/contract', body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open("Előnézet betöltése sikertelen", null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
      )
    }else{
      return this.httpClient.post<PaginatedAnswer<Contract>>(environment.serverEndpoint + '/user/contract',body, {headers: headers}).pipe(
          catchError(error => {
            this.snackBar.open("Szerződés mentése sikertelen", null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          }),
          finalize(() => this.progressService.removeTask(task.id))
      );
    }
  }

  modifyBackendContract(body, preview) {
    const task = this.progressService.addTask(this.constructor.name + '_modify_contract');
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    if(body.signed_by_id != this.originalFormData.signed_by || body.valid_from != this.originalFormData.valid_from || body.valid_to != this.originalFormData.valid_to){
      body.is_modified = true;
    }else{
      body.is_modified = false;
    }
    if(preview){
      // console.log(body);
      return this.httpClient.put<any>(environment.serverEndpoint + '/user/contract', body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open("Előnézet betöltése sikertelen", null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
      )
    }else{
      // console.log(body);
    return this.httpClient.put<PaginatedAnswer<Contract>>(environment.serverEndpoint + '/user/contract',body, {headers: headers}).pipe(
        catchError(error => {
          this.snackBar.open("Szerződés mentése sikertelen", null, {panelClass: 'error-snackbar'});
          console.log(error);
          return throwError(error);
        }),
        finalize(() => this.progressService.removeTask(task.id))
    );
  }
  }

  uploadFile(template){
    const dialog = this.dialogService.open(FileUploaderEditorComponent);
    dialog.componentInstance.customerId = this._customerId;
    dialog.componentInstance.label = false;
    dialog.componentInstance.contractId = this._contract.id;
    dialog.componentInstance.template = template;

    
    dialog.afterClosed().subscribe(closeResponse => {
      if (closeResponse){
        if (closeResponse.response.type == "OK") {
          this.snackBar.open("Sikeres fájlfelöltés!", "OK", {panelClass: "success-snackbar"});
        }
        else{
          catchError(error => {
            this.snackBar.open("Fájlfeltöltés sikertelen!", null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          })
        }
      }
    })
  }

  startESign(template){
    const dialog = this.dialogService.open(OkDialogComponent);
    this.translateService.get('companies_check-generated-kvvsz').subscribe((value)=>dialog.componentInstance.title = value)
    this.translateService.get('0').subscribe((value)=>dialog.componentInstance.negativeButtonText = value)
    this.translateService.get('1').subscribe((value)=>dialog.componentInstance.positiveButtonText = value)
    dialog.afterOpened().subscribe(() => {
      this.templateService.startESign(template, this._contract.id, false,true).then(res => {
        this.handleFileContent(res, "E-aláírás ellenőrzése");
      })
    })
    dialog.afterClosed().subscribe(response => {
      if(response){
        this.templateService.startESign(template, this._contract.id).then(res => {
          if(res.type == "OK"){
            this.snackBar.open("E-aláírás indítása sikeres!", "OK", {panelClass: 'success-snackbar'});
          }
          catchError(error => {
            this.snackBar.open("Ealáírás küldése sikertelen!", null, {panelClass: 'error-snackbar'});
            console.log(error);
            return throwError(error);
          })
        })
        this.dialogRef.close();
      }
      
    
    
  });   
  }

  reject(){
    if(!this.editable){
      this.dialogRef.close();
    }else {
      const dialog = this.dialogService.open(OkDialogComponent);
      this.translateService.get('attention').subscribe((value)=>dialog.componentInstance.title = value);
      this.translateService.get('companies_reject-modification-exit').subscribe((value)=>dialog.componentInstance.description = value)
      dialog.componentInstance.positiveButtonText = "Igen";
      dialog.componentInstance.negativeButtonText = "Nem";
      dialog.afterClosed().subscribe(response => {
        if (response) {
          this.dialogRef.close();
        }
      })
    }
  }


  checkForm():boolean {
    // this.validationErrors = []; // Reset errors
    // Object.keys(this.contractForm.controls).forEach(key => {
    //   const controlErrors = this.contractForm.get(key)?.errors;
    //   if (controlErrors != null) {
    //     for (const errorKey in controlErrors) {
    //       this.validationErrors.push(`${key} has error: ${errorKey}`);
    //     }
    //   }
    // });

    let inputSignedAtValue = this.inputSignedAt.nativeElement.value;
    if (!inputSignedAtValue) {
      this.translateService.get('error_message_contract_date_from_cannot_be_empty').subscribe((value) =>
          this.snackBar.open(value, null, {duration: 3000,panelClass: 'error-snackbar' }));
      return false;
    }

    let validFromValue = this.inputValidFrom.nativeElement.value;
    if (!validFromValue) {
      this.translateService.get('error_message_contract_validity_start_date_from_cannot_be_empty').subscribe((value) =>
      this.snackBar.open(value, null, {duration: 3000,panelClass: 'error-snackbar' }));
      return false;
    }

    if (inputSignedAtValue < validFromValue) {
      this.translateService.get('error_message_contract_date_invalid').subscribe((value) =>
          this.snackBar.open(value, null, {duration: 3000,panelClass: 'error-snackbar' }));
      return false;

    }

    let validToValue = this.inputValidTo.nativeElement.value;
    if (!validToValue) {
      this.translateService.get('error_message_contract_validity_end_date_from_cannot_be_empty').subscribe((value) =>
          this.snackBar.open(value, null, {duration: 3000,panelClass: 'error-snackbar' }));
      return false;
    }

    let bysValue = this.inputBusinessYearFrom.nativeElement.value;
    if (!bysValue) {
      this.translateService.get('error_message_contract_businessyear_start_date_from_cannot_be_empty').subscribe((value) =>
          this.snackBar.open(value, null, {duration: 3000,panelClass: 'error-snackbar' }));
      return false;
    }

    // if (bysValue < validFromValue) {
    //   this.translateService.get('error_message_contract_businessyear_start_date_invalid').subscribe((value) =>
    //       this.snackBar.open(value, null, {duration: 3000,panelClass: 'error-snackbar' }));
    //   return false;
    //
    // }

    let byeValue = this.inputBusinessYearTo.nativeElement.value;
    if (!byeValue) {
      this.translateService.get('error_message_contract_businessyear_end_date_from_cannot_be_empty').subscribe((value) =>
          this.snackBar.open(value, null, {duration: 3000,panelClass: 'error-snackbar' }));
      return false;
    }

    if (byeValue < bysValue) {
      this.translateService.get('error_message_contract_businessyear_end_date_invalid').subscribe((value) =>
          this.snackBar.open(value, null, {duration: 3000,panelClass: 'error-snackbar' }));
      return false;

    }


    return true;
  }

}
