import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WorkflowElement } from 'src/app/data/workflow-template';
import { AuditReportComponent } from '../audit-report/audit-report.component';
import { BalanceSheetComponent } from '../balance-sheet/balance-sheet.component';
import { UserWfsService } from '../../../services/user-wfs.service';
import { TranslateService } from '@ngx-translate/core';
import { WfTemplateService } from 'src/app/services/wf-template.service';
import {LanguageService} from "../../../services/language.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CredentialsService} from "../../../services/credentials.service";
import {ReportingService} from "../../../services/reporting-service";

@Component({
  selector: 'app-workflow-detail',
  templateUrl: './workflow-detail.component.html',
  styleUrls: ['./workflow-detail.component.less']
})
export class WorkflowDetailComponent implements OnInit {

  @Input() template;
  @Input() strictAssignmentControllSelectionItems;
  @Input() strictClosingControllSelectionItems;
  @Input() workflowMainGroupSelectionItems;
  @Input() elements;
  @Input() statusSelectionItems;
  @Input() contracts;
  @Input() customerSelectionItems;
  @Input() gvSchema_id;
  @Output() isDetailEvent = new EventEmitter<any>();
  selected = new FormControl(0);
  selectedWorkflowElement;

  math = Math;

  isElementDetail: boolean = false;
  setIsElementDetail(event){
    this.isElementDetail = event.value;
    this.getWfes();
    this.selectedWorkflowElement = event.element;
  }

  auditGroupControllSelectionItems = [{value: true, name: "1"}, {value: false, name: "0"}];
  compulsoryClosureSelectionItems = [{value: true, name: "1"}, {value: false, name: "0"}];

  coworkerSelectionItems = [];
  customer_id = 0;

  timeCodeSelectionItems = [];
  commentTypeSelectionItems = [];
  restrictionTypeSelectionItems = [];
  reportCreatorSelectionItems = [];
  currencySelectionItems = [{value: "HUF", name: "HUF"},{value: "EUR", name: "EUR"},{value: "USD", name: "USD"}];
  otherLegislationSelectionItems = [];
  localeItems: any = [];
  customer_currency_iso_code = "";

  constructor(protected snackBar: MatSnackBar,
              private dialogService: MatDialog,
              private userWfService: UserWfsService,
              private translateService: TranslateService,
              public wfTemplateService: WfTemplateService,
              private langService: LanguageService,
              private reportingService: ReportingService) { }

  ngOnInit(): void {
    this.customer_id = this.contracts.find(item => item.id == this.template.contract_id)!.customer_id;
    this.customer_currency_iso_code = this.customerSelectionItems.find(item => item.value == this.customer_id)!.currency_iso_code;
    this.getAllCoworkers();
    this.translateService.onLangChange.subscribe((event)=>{
      this.coworkerSelectionItems = [];
      this.getAllCoworkers();
    });

    this.wfTemplateService.getWfeTimes().subscribe((response)=>{
      response.payload.forEach((time)=>{
        if (time.is_active){
          this.timeCodeSelectionItems.push({value: time.sid, name: time.sid})
        }
      })
    })

    this.userWfService.getAROpinionForms().subscribe((response)=>{
      response.payload.forEach((types)=>{
        if (types.is_active){
          this.commentTypeSelectionItems.push({name: types.sid, value:types.sid})
        }
      })
    });

    this.userWfService.getARModifyReason().subscribe((response)=>{
      response.payload.forEach((types)=>{
        if (types.is_active){
          this.restrictionTypeSelectionItems.push({name: types.sid, value:types.sid})
        }
      })
    });

    this.userWfService.getARPlurality().subscribe((response)=>{
      response.payload.forEach((types)=>{
        if (types.is_active){
          this.reportCreatorSelectionItems.push({name: types.sid, value:types.sid})
        }
      })
    });

    this.userWfService.getAROtherLaw().subscribe((response)=>{
      response.payload.forEach((types)=>{
        if (types.is_active){
          this.otherLegislationSelectionItems.push({name: types.sid, value:types.sid})
        }
      })
    });
    //TODO
    //this.userWfService.getWf(this.template.id).subscribe((response) =>{})
    this.getLocaleItems();
  }

  getLocaleItems(){
    this.langService.loadLanguages().subscribe((response) => {
      response.payload.forEach(lang => {
        let singleLocal = {value:"", name:""}
        singleLocal.value = lang.locale;
        singleLocal.name = lang.locale;
        this.localeItems.push(singleLocal);
      })
    })
  }

  getAllCoworkers(){
    this.wfTemplateService.listCoworkers(CredentialsService.role).subscribe((response)=>{
      response.payload.content.forEach((coworker)=>{
        if (coworker.is_active && coworker.first_name != undefined){
          this.coworkerSelectionItems.push({name:this.createName(coworker), value:coworker.id})
        }
      })
    })
  }

  getWfes(){
    this.elements = [];
    this.userWfService.getWfes(this.template.id).subscribe((response)=>{
      response.payload.content.forEach((wfe) =>{
        if (wfe.is_active){
          this.elements.push(wfe);
          if(wfe.workflow_element_type_sid == 'WFE_TYPE_GV')this.gvSchema_id=wfe.id;
        }
      });
      this.elements.sort((a, b) => {
        if (a.workflow_element_group.serial_number > b.workflow_element_group.serial_number) {
          return 1
        }
        if (a.workflow_element_group.serial_number < b.workflow_element_group.serial_number) {
          return -1
        }
        return 0;
      });
      // console.log("YYY");
      // console.log(this.elements);

    })
  }

  createName(person){
    return localStorage.getItem("language") == "hu_HU" ? person.last_name + " " + person.first_name : person.first_name + " " + person.last_name
  }

  createDateString(date){
    return new Date(date).toLocaleDateString(localStorage.getItem("language").replace("_", "-"))
  }

  goBack(){
    this.isDetailEvent.emit(false);
  }

  generateAuditReport(){
    const dialog = this.dialogService.open(AuditReportComponent);
    dialog.componentInstance.commentTypeSelectionItems = this.commentTypeSelectionItems;
    dialog.componentInstance.restrictionTypeSelectionItems = this.restrictionTypeSelectionItems;
    dialog.componentInstance.reportCreatorSelectionItems = this.reportCreatorSelectionItems;
    dialog.componentInstance.currencySelectionItems = this.currencySelectionItems;
    dialog.componentInstance.otherLegislationSelectionItems = this.otherLegislationSelectionItems;
    dialog.componentInstance.workflowId = this.template.id;
    dialog.componentInstance.localeItems = this.localeItems;
  }

  openWorkflowElement(){
    this.isElementDetail = true;
  }

  findById(selection, id){
    return selection.find(item => item.id == id)
  }

  
  findSelectionItem(selection, value){
    return selection.find(item => item.value == value);
  }

  fillBalanceSheet(){
    if(this.gvSchema_id){
      this.userWfService.getBalanceSheetSchema(this.gvSchema_id, 'GV_SCHEMA_YEARLY').subscribe((response)=>{
        if(response){
          if(response.type == "OK"){
            const dialog = this.dialogService.open(BalanceSheetComponent, {width:'90vw'});
            dialog.componentInstance.schemaItems = response.payload;
            dialog.componentInstance.gvSchema_id = this.gvSchema_id;
            dialog.componentInstance.customer_id = this.customer_id;
          }
        }
      })
    }else{
      this.snackBar.open("Kérem, a funkció futtatásához hozzon létre egy Mérlegséma típusú workflow-elemet!", null, {panelClass: 'error-snackbar'});
    }
  }

  getTotalWorkHours(): number {
    const total = this.elements.reduce((sum, element) => sum + (element.estimated_work_hours || 0), 0);
    // return Math.floor(total / 3600);
    return parseFloat((total / 3600).toFixed(1));
  }
  listOfElement() {
    let body = {
      workflow_id: this.template.id,
      locale: localStorage.getItem("language"),
    }
    this.reportingService.getReportRX(body,"wfelist").subscribe((response)=>{
      this.handleFileContent(response)
    })
  }

  handleFileContent(response: { type: string; payload: { content: string; mime_type: any; name: string; }; }){
    if(response){
      if(response.type == "OK"){
        if(response.payload.content){
          let byteCharacters = atob(response.payload.content);
          let byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          let byteArray = new Uint8Array(byteNumbers);
          let file = new File([byteArray], `Report`, { type: response.payload.mime_type});

          let link = document.createElement("a");
          link.href = URL.createObjectURL(file);
          //'data:'+response.payload.mime_type+',' + byteArray;
          link.download = response.payload.name;
          link.click();

        }else{
          this.snackBar.open("A megadott feltételekkel nincs adat az adatbázisban!", null, {panelClass: 'success-snackbar'});
        }
      }
    }
  }

}
