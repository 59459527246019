import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OkDialogComponent } from '../../ok-dialog/ok-dialog.component';
import { WorkflowTemplateBasicEditorComponent } from '../../workflow-templates/workflow-template-basic-editor/workflow-template-basic-editor.component';
import { UserWfsService } from '../../../services/user-wfs.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CompanyService} from '../../../services/company.service';

@Component({
  selector: 'app-workflow-create',
  templateUrl: './workflow-create.component.html',
  styleUrls: ['./workflow-create.component.less']
})
export class WorkflowCreateComponent implements OnInit {

  _selectedCompany: any;

  get selectedCompany() { return this._selectedCompany };
  set selectedCompany(value: any) {
    this._selectedCompany = value;
  }

  newWorkflowForm: FormGroup = this.formBuilder.group({
    id: null,
    base: [null, Validators.required],
    dateWorkflow: [null],
    templateWorkflow: [null],
    workflow: [null],
    company: [null, Validators.required],
  })

  reportTypeSelectionItems = [];

  _previousDateSelectionItems: any;
  get previousDateSelectionItems() { return this._previousDateSelectionItems };
  set previousDateSelectionItems(value: any) {
    this._previousDateSelectionItems = value;
  }

  _templateSelectionItems: any;
  get templateSelectionItems() { return this._templateSelectionItems };
  set templateSelectionItems(value: any) {
    this._templateSelectionItems = value;
  }

  _workflowCompanySelectionItems: any;
  get workflowCompanySelectionItems() { return this._workflowCompanySelectionItems };
  set workflowCompanySelectionItems(value: any) {
    this._workflowCompanySelectionItems = value;
  }

  _workflows = [];
   get workflows() { return this._workflows };
   set workflows(value: any) {
   this._workflows = value;
   }

  /*_workflowTemplateSelectionItems: any;
  get workflowTemplateSelectionItems() { return this._workflowTemplateSelectionItems };
  set workflowTemplateSelectionItems(value: any) {
    this._workflowTemplateSelectionItems = value;
  }*/

  workflowTemplateSelectionItems = [];

  _hasPrevWF: boolean = false;
  get hasPrevWF() { return this._hasPrevWF };
  set hasPrevWF(value: boolean) {
    this._hasPrevWF = value;
  }

  _contracts =[] ;
  get contracts() {
    return this._contracts
  };

  set contracts(value: any) {
    this._contracts = value;
  }


  _selectedRow : any;
  get selectedRow() {
    return this._selectedRow
  };
  set selectedRow(value: any) {
    this._selectedRow = value;
  }

  private selComp: any;

  listContracts(value: any, contr:any ) {

    if (value) {
    this.companyService.listContracts({customer_id: value, id: contr}).subscribe((response) => {
      this.contracts = response.payload.content;
      // console.log("Contracts0:");
      // console.log(this.contracts);

    })


      this.service.getWfsByCustomer(value).subscribe((response) => {
        // console.log("Workflows:");
        // console.log(response.payload.content.length);
        this.workflows = response.payload.content;
        this.hasPrevWF = response.payload.content.length > 0;
      });
    }
  }

  translatableTexts: any[] = [
    {value: "common_message_attention", name: ""},
    {value: "common_message_discard_changes_and_exit", name: ""},
    {value: "1", name: ""},
    {value: "0", name: ""}
  ];
  private contrId: any;
  private wfid: any;

  constructor(private dialogService: MatDialog, public dialogRef: MatDialogRef<WorkflowCreateComponent>,
              private formBuilder: FormBuilder,
              private service: UserWfsService,
              private translateService: TranslateService,
              private companyService: CompanyService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    // this.changeBase("empty");

    // console.log("Comps:");
    // console.log(this.workflowCompanySelectionItems);
    //
    // console.log("SelComp:");
    // console.log(this.selectedCompany); // a filterben kiválasztott cég
    //
    // console.log("selected:");
    // console.log(this.selectedRow);

    this.contrId = 0;
    this.wfid = 0;

    if (this.selectedRow) {
      this.selComp = this.selectedRow.customer_id;
    }
    if (this.selComp) {
        this.contrId = this.selectedRow.contract_id;
        this.wfid = this.selectedRow.id;
        // console.log("selected row");
    }  else {
      // console.log("filtered company");
      this.selComp = this.selectedCompany.value;
      this.contrId = 0;
    }

    if (this.selComp) {

      this.listContracts(this.selComp, this.contrId);
    }

    this.newWorkflowForm.controls.company.setValue(this.selComp); //this.selectedCompany.value);

    this.newWorkflowForm.controls.company.valueChanges.subscribe((response)=>{
      if (response){
        /*this.service.getWfsTable({contract_id: response}).subscribe((wfResponse)=>{
          let wfs = [];
          wfResponse.payload.content.forEach((wf)=>{
            if (wf.is_active){
              wfs.push({name: wf.caption, value: wf.id})
            }
          })
          this.workflowTemplateSelectionItems = wfs;
        })*/
        this.changeCompany();

      }
      else{
        this.workflowTemplateSelectionItems = [];
        this.newWorkflowForm.controls.workflow.setValue(null);
        this.newWorkflowForm.controls.workflow.updateValueAndValidity();
      }
    })

    this.service.getARTypes().subscribe((response)=>{
      response.payload.forEach((types)=>{
        if (types.is_active){
          this.reportTypeSelectionItems.push({name: types.sid, value:types.sid})
        }
      })
    });

    this.translateService.stream(['common_message_attention', 'common_message_discard_changes_and_exit', '1', '0']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_message_attention", name: res["common_message_attention"]},
        {value: "common_message_discard_changes_and_exit", name: res["common_message_discard_changes_and_exit"]},
        {value: "1", name: res["1"]},
        {value: "0", name: res["0"]}
      ];
    });
  }

  changeBase(value){
    // switch(value){
    //   case "empty":
    //     this.newWorkflowForm.controls.dateWorkflow.disable();
    //     this.newWorkflowForm.controls.templateWorkflow.disable();
    //     this.newWorkflowForm.controls.workflow.disable();
    //     this.newWorkflowForm.controls.company.disable();
    //     this.newWorkflowForm.controls.dateWorkflow.reset();
    //     this.newWorkflowForm.controls.templateWorkflow.reset();
    //     this.newWorkflowForm.controls.workflow.reset();
    //     this.newWorkflowForm.controls.company.reset();
    //     break;
    //   case "previousDate":
    //     this.newWorkflowForm.controls.dateWorkflow.enable();
    //     this.newWorkflowForm.controls.templateWorkflow.disable();
    //     this.newWorkflowForm.controls.workflow.disable();
    //     this.newWorkflowForm.controls.company.disable();
    //     this.newWorkflowForm.controls.templateWorkflow.reset();
    //     this.newWorkflowForm.controls.workflow.reset();
    //     this.newWorkflowForm.controls.company.reset();
    //     break;
    //   case "template":
    //     this.newWorkflowForm.controls.dateWorkflow.disable();
    //     this.newWorkflowForm.controls.templateWorkflow.enable();
    //     this.newWorkflowForm.controls.workflow.disable();
    //     this.newWorkflowForm.controls.company.disable();
    //     this.newWorkflowForm.controls.dateWorkflow.reset();
    //     this.newWorkflowForm.controls.workflow.reset();
    //     this.newWorkflowForm.controls.company.reset();
    //     break;
    //   case "existingWorkflow":
    //     this.newWorkflowForm.controls.dateWorkflow.disable();
    //     this.newWorkflowForm.controls.templateWorkflow.disable();
    //     this.newWorkflowForm.controls.workflow.enable();
    //     this.newWorkflowForm.controls.company.enable();
    //     this.newWorkflowForm.controls.dateWorkflow.reset();
    //     this.newWorkflowForm.controls.templateWorkflow.reset();
    //     break;
    // }

  }

  reject(){
    const dialog = this.dialogService.open(OkDialogComponent);
    dialog.componentInstance.title = this.translatableTexts.find(element => element.value === "common_message_attention").name;
    dialog.componentInstance.description = this.translatableTexts.find(element => element.value === "common_message_discard_changes_and_exit").name;
    dialog.componentInstance.positiveButtonText = this.translatableTexts.find(element => element.value === "1").name;
    dialog.componentInstance.negativeButtonText = this.translatableTexts.find(element => element.value === "0").name;
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.dialogRef.close();
      }
    })
  }

  next(){

    if (
        this.workflowCompanySelectionItems.find((item) => item.validity != "CUS_STAT_ACTIVE" && item.value == this.newWorkflowForm.controls.company.value)
    ) {
      this.translateService.get('error_message_newwf_customer_status').subscribe((value) =>
          this.snackBar.open(value, null, {duration: 3000,panelClass: 'error-snackbar' }));

    } else {
      const dialog = this.dialogService.open(WorkflowTemplateBasicEditorComponent);
      dialog.componentInstance.newWorkflowForm = this.newWorkflowForm;
      dialog.componentInstance.customerSelectionItems = this.workflowCompanySelectionItems;
      dialog.componentInstance.reportTypeSelectionItems = this.reportTypeSelectionItems;
      dialog.componentInstance.selectedCompany = this.newWorkflowForm.controls.company;
      dialog.componentInstance.origPage = 'create';
      dialog.componentInstance.contracts = this.contracts;
      dialog.componentInstance.workflows = this.workflows;
      dialog.componentInstance.templateWorkflowId = this.wfid;
      dialog.afterClosed().subscribe((response) => {
        this.dialogRef.close(response);
      })
    }
  }

  changeCompany(){
    // console.log("change company");
    if(this.newWorkflowForm.controls['company'].value){
      this.workflowTemplateSelectionItems = [];
      this.workflows.forEach(template => {
        if(template.customer_id == this.newWorkflowForm.controls['company'].value) {
          this.workflowTemplateSelectionItems.push({"value": template.id, "name": template.caption})
        }
      })
      this.selComp = this.newWorkflowForm.controls['company'].value;
      this.listContracts(this.newWorkflowForm.controls['company'].value, 0);
    }
  }

  checkForm() {
    console.log(this.newWorkflowForm.controls)
  }

}
