import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {Company} from '../../data/company';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {CompanyEditorComponent} from './company-editor/company-editor.component';
import {MatDialog} from '@angular/material/dialog';
import {CompanyService} from '../../services/company.service';
import { StorageService } from 'src/app/services/storage.service';
import { FilterTableComponent } from '../filter-table/filter-table.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { merge, of, Subscription } from 'rxjs';
import { PaginatedAnswer } from 'src/app/data/response';
import { OkDialogComponent } from '../ok-dialog/ok-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.less']
})
export class CompaniesComponent implements OnInit, AfterViewInit {
  allColumns = [{value:'long_name', name: 'Cég neve'},  {value:'tax_number', name: 'Cég formázott adószáma'},  {value:'email_address', name: 'Kapcsolattartó email'},  {value:'customer_status_sid', name: 'Státusz'},  {value:'contractType', name: 'Aktuális szerződés jellege'},  {value:'contractStatus', name: 'Aktuális szerződés státusza'}]

  displayedColumns: string[] = ['select', 'long_name', 'tax_number', 'email_address', 'customer_status_sid', 'contractType', 'contractStatus'];
  dataSource = new MatTableDataSource<Company>([]);
  selection = new SelectionModel<Company>(false, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSize: number = 10;
  currentPage: number = 0;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  countryDims = [];

  showContracts: boolean = false;

  //TODO contractType, contractStatus name
  columnSelectionItems: any[] = [{value: "long_name", name: ""}, {value: "tax_number", name: ""}, {value: "email_address", name: ""}, {value: "customer_status_sid", name: ""}, {value: "contractType", name: ""}, {value: "contractStatus", name: ""}];

  columnSelectionForm: FormGroup = this.formBuilder.group({
    columnName: "long_name"
  });

  searchPattern: string ="";
  searchColumn: string = this.columnSelectionForm.get("columnName").value;
  columnNameSubscription: Subscription;

  @ViewChild('searchInput') searchInput;

  signatoryAuditorSelectionItems = [];
  decisionSelectionItems = [{value: "accepted", name: 'company-accepted'}]
  statusSelectionItems: any[] = [];

  _showDeletedCompanies = false;
  set showDeletedCompanies(value: boolean) {
    this._showDeletedCompanies = value;
    this.loadTableData();
  }

  get showDeletedCompanies() {
    return this._showDeletedCompanies;
  }

  _showCompaniesAssignedToOthers = false;
  set showCompaniesAssignedToOthers(value: boolean) {
    this._showCompaniesAssignedToOthers = value;
    this.loadTableData();
  }

  get showCompaniesAssignedToOthers() {
    return this._showCompaniesAssignedToOthers;
  }

  createName(person){
    return localStorage.getItem("language") == "hu_HU" ? person.last_name + " " + person.first_name : person.first_name + " " + person.last_name
  }

  constructor(private dialogService: MatDialog, private service: CompanyService, private storageService: StorageService, private snackBar: MatSnackBar, private formBuilder: FormBuilder, private translateService: TranslateService, private router: Router) {
  }

  ngOnInit(): void {
    this.service.getItems().subscribe(companyList => {
      this.dataSource.data = companyList;
      // console.log(companyList);
    });

    this.service.getSigners().subscribe((response)=>{
      
      response.payload.forEach((signer)=>{
          if(signer.first_name)this.signatoryAuditorSelectionItems.push({ value: signer.id, name: this.createName(signer)});
      });
    })

    this.columnNameSubscription = this.columnSelectionForm.get("columnName").valueChanges.subscribe((data: any) => this.searchColumn = data)

    this.translateService.stream(['companies_name-of-company', 'companies_company-formatted-tax-number', 'companies_contact-person-mail', 'companies_status', 'companies_actual-contract-aspect', 'companies_actual-contract-status']).subscribe((res: string) => {
      this.columnSelectionItems = [{value: "long_name", name: res["companies_name-of-company"]}, {value: "tax_number", name: res["companies_company-formatted-tax-number"]}, {value: "email_address", name: res["companies_contact-person-mail"]}, {value: "customer_status_sid", name: res["companies_status"]}, {value: "contractType", name: res["companies_actual-contract-aspect"]}, {value: "contractStatus", name: res["companies_actual-contract-status"]}];
    });

    this.storageService.readJsonStorage({"name": ["companiesTableColumns"]}).subscribe((response) => {
      if(response.companiesTableColumns){
        this.displayedColumns = JSON.parse(response.companiesTableColumns).json; 
      }    
    })

    this.service.getCountryDimList().subscribe((response)=>{
//      console.log(response);
      response.payload.forEach((dimension)=>{
        if (dimension.dim_status_sid !== 'DIM_STAT_DISABLED'){
          if(dimension.dim_code!="city" && dimension.dim_code!="postal_code")this.countryDims.push(dimension);
        }
      });
      this.countryDims.sort(function (a, b) {return a.serial_number - b.serial_number})
    })

    this.service.getCustomerStatuses().subscribe((response)=>{
      response.payload.forEach((status)=>{
        if (status.is_active){
          this.statusSelectionItems.push({name:status.sid, value:status.sid})
        }
      })
    })
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.currentPage = 1;
    });

    this.loadTableData();
    //this.paginator.length = this.resultsLength;
  }

  ngOnDestroy(){
    this.columnNameSubscription.unsubscribe();
  }

  showDeletedCompaniesChg() {
    this.loadTableData();
  }

  loadTableData(response?){
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          let searchPhrase = {
            "operator" : "AND",
            "filters": [
                {
                  "column": this.searchColumn,
                  "pattern": this.searchPattern
                }
              ]};
              console.log(this.searchPattern);
          let req = {
            "page": this.currentPage +1,
            "size": this.pageSize,
            "deleted": this.showDeletedCompanies,
            "others": this.showCompaniesAssignedToOthers,
            "order": [
              {
                "column": this.sort.active ? this.sort.active : "long_name",
                "direction": this.sort.direction ? this.sort.direction : "asc"
              }
            ]
          };
          console.log(req);
          req["toggle"] = {"operator":"AND", "filters":[{"column":"is_active", "value": 1}]};
          if(this.searchColumn != "" && this.searchPattern != ""){
            req["like"] = {
              "operator" : "AND",
              "filters": [
                {
                  "column": this.searchColumn,
                  "pattern": this.searchPattern
                }
              ]
            };
          }
          return this.service.listCompanies(req);
        }),
        map((data: PaginatedAnswer<Company>) => {
          // console.log(data);
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.payload.total;
          return data.payload.content;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return of([]);
        })
    ).subscribe((data) => {
      //TODO BE szűrés
      let datas = [];
      console.log(this.resultsLength);
      data.forEach((item)=>{
        if (item.is_active){
          datas.push(item)
        }
      })
      this.dataSource.data = datas;
      console.log(datas);
      //this.paginator.length = this.resultsLength;
      //this.dataSource.data = data;
      if (this.isEdit){
        let index = this.dataSource.data.findIndex(data=>data.id == response.payload.id)
        if (index >- 1){
          this.selection.select(this.dataSource.data[index]);
        }
        this.isEdit = false;
      }
      else{
        this.selection.clear();
      }
    });
  }

  handlePage(event){
    console.log(event);
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    console.log(this.paginator);
    this.selection.clear();
  }
  
  findSelectionItem(selection, value){
    return selection.find(item => item.value == value);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  searchCompany(event){
    this.searchPattern = event.target.value;
    this.loadTableData();
  }

  removeItem(arr, value) {
    for (let [i, element] of arr.entries()) {
      if (element.dim_code == value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  clearSearch(){
    this.searchInput.nativeElement.value = null;
    this.searchPattern = null;
    this.loadTableData();
  }

  isEdit = false;
  editCompany(company?: Company) {
    const dialog = this.dialogService.open(CompanyEditorComponent);
    dialog.componentInstance.signatoryAuditorSelectionItems = this.signatoryAuditorSelectionItems;
    dialog.componentInstance.decisionSelectionItems = this.decisionSelectionItems;
    dialog.componentInstance.statusSelectionItems = this.statusSelectionItems;

    let filteredDims = this.countryDims;
    this.removeItem(filteredDims, "long_name");
    this.removeItem(filteredDims, "currency_iso_code");
    this.removeItem(filteredDims, "primary_bank_account_account");
    this.removeItem(filteredDims, "company_registration_number");
    this.removeItem(filteredDims, "bank_accounts");
    this.removeItem(filteredDims, "primary_representative_tax_number");
    console.log("Filtered_Dims:");
    console.log(filteredDims);

    dialog.componentInstance.countryDims = filteredDims;
    if (company) {
      console.log(company);
      dialog.componentInstance.company = company;
    }
    dialog.afterClosed().subscribe((response) => {
      if (response){
        if (response.type == "OK"){
          this.snackBar.open("A cég létrehozása sikeres", null, {panelClass: 'success-snackbar'});
          if (company){
            this.isEdit = true;
          }
          this.loadTableData(response);
        }
        else{
          this.snackBar.open("A cég létrehozása sikertelen", null, {panelClass: 'error-snackbar'});
          let dialog = this.dialogService.open(ErrorDialogComponent);
          dialog.componentInstance.error = response.payload;
        }
      } 
    });
  }

  setShowContracts(event){
    this.showContracts = event;
    setTimeout(()=>{
      this.isEdit = true;
      this.loadTableData({payload: this.selection.selected[0]});
    })
  }

  editContracts(){
    this.showContracts = true;  
  }

  openWorkflows(company){
    this.router.navigateByUrl("/workflows", { state: { customer: company.id }})
  }

  filterTableColumns(){
    const dialog = this.dialogService.open(FilterTableComponent);
    dialog.componentInstance.tableColumns = this.allColumns;
    dialog.componentInstance.displayedColumns = this.displayedColumns;
    dialog.componentInstance.table = "companies";
    dialog.afterClosed().subscribe(() => {
        this.storageService.readJsonStorage({"name": ["companiesTableColumns"]}).subscribe((response) => {
          if (response.companiesTableColumns){
           this.displayedColumns = JSON.parse(response.companiesTableColumns).json;      
          }
      })
    })
  }

  reorderColumns(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    let body = {
      "name": "companiesTableColumns",
      "value": {
        "json": this.displayedColumns
      }}
    this.storageService.writeJsonStorage(body).subscribe();
  }
}
