import {HttpClient} from "@angular/common/http";
import {Component, OnInit} from "@angular/core";
import {FormGroup, Validators, FormBuilder} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FileSystemService} from "../../../services/file-system.service";
import {UserWfsService} from "../../../services/user-wfs.service";

@Component({
  selector: 'app-file-uploader-independent-editor',
  templateUrl: './file-uploader-independent-editor.component.html',
  styleUrls: ['./file-uploader-independent-editor.component.less']
})
export class FileUploaderIndependentEditorComponent implements OnInit {

  fileForm: FormGroup = this.formBuilder.group({
    state: [null],
    file: [null, [Validators.required]],
  });

  _workflowId: any;
  get workflowId() { return this._workflowId };
  set workflowId(value: any) {
    this._workflowId = value;
  }

  _coworkerId: any;
  get coworkerId() { return this._coworkerId };
  set coworkerId(value: any) {
    this._coworkerId = value;
  }

  _isStated: any;
  get isStated() { return this._isStated };
  set isStated(value: any) {
    this._isStated = value;
  }

  constructor(private formBuilder: FormBuilder, public http: HttpClient, private snackBar: MatSnackBar,  public dialogRef: MatDialogRef<FileUploaderIndependentEditorComponent>, private service: UserWfsService) { }


  ngOnInit(): void {
    if (this.isStated) {
      this.fileForm.get("state").setValue(true);
    }
  }

  async saveIndependentFile(){
    console.log("In saveIndependentFile");
    if (this.fileForm.valid) {

      let content;
      if (this.fileForm.get("file").value instanceof File){
        content = await this.toBase64String(this.fileForm.get("file").value);
        content = content.split(",")[1];
      }

      let body = {
        is_independent: this.fileForm.get("state").value,
        content: content,
        workflow_id: this.workflowId,
        coworker_id: this.coworkerId
      }


      this.service.addIndependenceFile(body).subscribe((response) => {
        console.log("In addfile:" + this.fileForm.get("state").value);
        this.dialogRef.close(this.fileForm.get("state").value);
      });
    }
    else{
      this.fileForm.markAllAsTouched();
    }
  }

  closeIFdialog(){
    console.log("Closing");
    this.dialogRef.close(-1);
  }

  toBase64String(file: File){
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      }
      reader.onerror = error => reject(error);
    });
  }
}
