import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {ReportingService} from "../../../services/reporting-service";
import {DateParsePipe} from "../../../pipes/date-parse.pipe";
import {DateFormatPipe} from "../../../pipes/date-format.pipe";
import {DateAdapter} from "@angular/material/core";
import {UserWfsService} from "../../../services/user-wfs.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-report-munkatars-timesheet',
  templateUrl: './report-munkatars-timesheet.component.html',
  styleUrls: ['./report-munkatars-timesheet.component.less']
})
export class ReportMunkatarsTimesheetComponent implements OnInit {

  private _coworkerSelectionItems: any;
  get coworkerSelectionItems() { return this._coworkerSelectionItems };
  @Input() set coworkerSelectionItems(value: any) {
    this._coworkerSelectionItems = value;
  }

  private _signerSelectionItems: any;
  get signerSelectionItems() { return this._signerSelectionItems };
  @Input() set signerSelectionItems(value: any) {
    this._signerSelectionItems = value;
  }

  _customerSelectionItems: any;
  get customerSelectionItems() { return this._customerSelectionItems };
  @Input() set customerSelectionItems(value: any) {
    this._customerSelectionItems = value;
  }

  _workflowSelectionItems: any;
  get workflowSelectionItems() { return this._workflowSelectionItems };
  @Input() set workflowSelectionItems(value: any) {
    this._workflowSelectionItems = value;
  }

  _workflows: any;
  get workflows() { return this._workflows };
  @Input() set workflows(value: any) {
    this._workflows = value;
  }

  sequenceSelectionItems = [{value: "report_actarendery_sort_filename", name: "Fájl neve"}, {value: "report_actarendery_sort_folder", name: "Könyvtár"}];
  customerSelectionItemsFiltered = [];

  private _reportType: any;
  @Input() set reportType(value: any){
    this._reportType = value;
    this.changeForm(value.name);
    this.formCaption = value.caption;
  }
  get reportType(): any {
    return this._reportType;
  }

  formCaption = "";
  intervallumStartVisible = false;
  intervallumEndVisible = false;
  coworkerVisible = false;
  customerVisible = false;
  signerVisible = false;
  singleCustomerVisible = false;
  singleWorkflowVisible = false;
  sequenceVisible = false;
  xmlExportVisible = false;
  liveOnlyVisible = false;
  passedOnlyVisible = false;

  isLiveOnly = true;
  isPassedOnly = false;

  workflowOwnerCustomerSelectionItems = [];

  company_value_change_subscription;

  reportForm: FormGroup = this.formBuilder.group({
    id: null,
    intervalStartDate: [null, Validators.required],
    intervalEndDate: [null, Validators.required],
    coworker_ids: [null, Validators.required],
    signer_ids: [null, Validators.required],
    single_customer_id: [null, Validators.required],
    single_workflow_id: [null, Validators.required],
    customer_ids: [null, Validators.required],
    output: [null, Validators.required],
    sequence_sid: [null, Validators.required],
    is_export_xml: [null, Validators.required],
    is_liveOnly: [null],
    is_passedOnly: [null]
  })

  constructor(private snackBar: MatSnackBar, private pipeParse: DateParsePipe, private pipeFormat: DateFormatPipe, private _adapter: DateAdapter<any>, private service: UserWfsService, public dialogRef: MatDialogRef<ReportMunkatarsTimesheetComponent>, private formBuilder: FormBuilder, private reportingService: ReportingService) { }

  @ViewChild("input_period_start") inputPeriodStart: ElementRef;
  @ViewChild("input_period_end") inputPeriodEnd: ElementRef;

  ngOnInit(): void {
    console.log(this.workflows);
    this.workflows.forEach((wf) => {

    })
  }

  changeForm(typ){
    this.reportForm.reset();
    this.intervallumStartVisible = false;
    this.intervallumEndVisible = false;
    this.coworkerVisible = false;
    this.customerVisible = false;
    this.singleCustomerVisible = false;
    this.singleWorkflowVisible = false;
    this.sequenceVisible = false;
    this.signerVisible = false;
    this.xmlExportVisible = false;
    this.liveOnlyVisible = false;
    this.passedOnlyVisible = false;

    if(this.company_value_change_subscription)this.company_value_change_subscription.unsubscribe();
    if(typ == "r1"){
      this.intervallumStartVisible = true;
      this.intervallumEndVisible = true;
      this.coworkerVisible = true;
      this.customerVisible = true;
    }
    if(typ == "r2"){
      this.intervallumStartVisible = true;
      this.intervallumEndVisible = true;
      this.coworkerVisible = true;
      this.customerVisible = true;
    }
    if(typ == "r3"){
      this.singleCustomerVisible = true;
      this.singleWorkflowVisible = true;
      this.company_value_change_subscription = this.reportForm.controls.single_customer_id.valueChanges.subscribe((response)=>{
        this.setWorkflows()
      })
    }
/*    
    if(typ == "r4"){
      this.intervallumStartVisible = true;
      this.intervallumEndVisible = true;
      this.coworkerVisible = true;
      this.customerVisible = true;
    }
*/    
    if(typ == "r5"){
      this.intervallumStartVisible = true;
      this.intervallumEndVisible = true;
      this.coworkerVisible = true;
      this.customerVisible = true;
    }
    if(typ == "r6"){
      this.singleCustomerVisible = true;
      this.intervallumStartVisible = true;
      this.intervallumEndVisible = true;
      this.sequenceVisible = true;
    }
    if(typ == "r7"){
      this.intervallumStartVisible = true;
      this.intervallumEndVisible = true;
      this.xmlExportVisible = true;
    }
    if(typ == "r8"){ ////////// Szerződések listája aláírónként
      this.signerVisible = true;
      this.intervallumStartVisible = true;
      this.liveOnlyVisible = true;
      this.passedOnlyVisible = true;
    }
    if(typ == "r9"){ ////////// Mérlegséma
      this.singleCustomerVisible = true;
      this.singleWorkflowVisible = true;
      this.company_value_change_subscription = this.reportForm.controls.single_customer_id.valueChanges.subscribe((response)=>{
        this.setWorkflows()
      })
    }
    if(typ == "r10"){ ////////// Mérlegséma
      this.coworkerVisible = true;
      this.intervallumStartVisible = true;
    }
    if(typ == "r11"){ ////////// Leterheltség
      this.coworkerVisible = true;
      this.intervallumStartVisible = true;
      this.intervallumEndVisible = true;
//       this.customerVisible = true;
    }
    if(typ == "r12"){ ////////// Leterheltség
      this.coworkerVisible = true;
      this.intervallumStartVisible = true;
      this.intervallumEndVisible = true;
      this.customerVisible = true;
    }    
  }

  setWorkflows(){
    this.workflowSelectionItems = [];
      this.workflows.forEach((wf)=>{
        if (wf.is_active && wf.customer_id == this.reportForm.controls.single_customer_id.value){
          this.workflowSelectionItems.push({name: wf.caption, value: wf.id});
        }
      });
      this.reportForm.controls.single_workflow_id.updateValueAndValidity();
  }

  handleFileContent(response){
    if(response){
      if(response.type == "OK"){
        if(response.payload.content){
        let byteCharacters = atob(response.payload.content);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let file = new File([byteArray], `Report`, { type: response.payload.mime_type});
        
        let link = document.createElement("a");
          link.href = URL.createObjectURL(file);
        //'data:'+response.payload.mime_type+',' + byteArray;
        link.download = response.payload.name;
        link.click();        
        
//        var fileURL = URL.createObjectURL(file);
//console.log("URL: "+fileURL);
//        window.open(fileURL);
        }else{
          this.snackBar.open("A megadott feltételekkel nincs adat az adatbázisban!", null, {panelClass: 'success-snackbar'});
        }
      }
    }
  }

  generate(){
    if(this.reportType.name == "r1"){
      let body = {
        interval_start: new Date(this.pipeParse.transform(this.inputPeriodStart.nativeElement.value)).getTime() / 1000,
        interval_end: new Date(this.pipeParse.transform(this.inputPeriodEnd.nativeElement.value)).getTime() / 1000,
        // fixme: multiselect!
        coworker_ids: this.reportForm.controls.coworker_ids.value,
        customer_ids: this.reportForm.controls.customer_ids.value,
//        coworker_id: this.reportForm.controls.coworker_ids.value,
//        customer_id: this.reportForm.controls.customer_ids.value,
        locale: localStorage.getItem("language"),
      }
      this.reportingService.getReportRX(body, "coworkertimesheet").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }

    if(this.reportType.name == "r2"){
      let body = {
        interval_start: new Date(this.pipeParse.transform(this.inputPeriodStart.nativeElement.value)).getTime() / 1000,
        interval_end: new Date(this.pipeParse.transform(this.inputPeriodEnd.nativeElement.value)).getTime() / 1000,
        // fixme: multiselect!
        coworker_id: this.reportForm.controls.coworker_ids.value,
        customer_id: this.reportForm.controls.customer_ids.value,
//        coworker_id: this.reportForm.controls.coworker_ids.value,
//        customer_id: this.reportForm.controls.customer_ids.value,
        locale: localStorage.getItem("language"),
      }
      this.reportingService.getReportRX(body, "customertimesheet").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }

    if(this.reportType.name == "r3"){
      let body = {
        customer_id: this.reportForm.controls.single_customer_id.value,
        workflow_id: this.reportForm.controls.single_workflow_id.value,
        locale: localStorage.getItem("language"),
      }
      this.reportingService.getReportRX(body, "wfegrouputilization").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }
/*
    if(this.reportType.name == "r4"){
      let body = {
        interval_start: new Date(this.pipeParse.transform(this.inputPeriodStart.nativeElement.value)).getTime() / 1000,
        interval_end: new Date(this.pipeParse.transform(this.inputPeriodEnd.nativeElement.value)).getTime() / 1000,
        // fixme: multiselect!
        coworker_ids: this.reportForm.controls.coworker_ids.value,
        customer_ids: this.reportForm.controls.customer_ids.value,
//        coworker_id: this.reportForm.controls.coworker_ids.value,
//        customer_id: this.reportForm.controls.customer_ids.value,
        locale: localStorage.getItem("language"),
      }
      this.reportingService.getReportRX(body, "wfegroupcoworker").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }
*/
    if(this.reportType.name == "r5"){
      let body = {
        interval_start: new Date(this.pipeParse.transform(this.inputPeriodStart.nativeElement.value)).getTime() / 1000,
        interval_end: new Date(this.pipeParse.transform(this.inputPeriodEnd.nativeElement.value)).getTime() / 1000,
        // fixme: multiselect!
        coworker_id: this.reportForm.controls.coworker_ids.value[0],
        customer_id: this.reportForm.controls.customer_ids.value[0],
//        coworker_id: this.reportForm.controls.coworker_ids.value,
//        customer_id: this.reportForm.controls.customer_ids.value,
        locale: localStorage.getItem("language"),
      }
      this.reportingService.getReportRX(body,"wfegroupcustomer").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }

    if(this.reportType.name == "r6"){
      let body = {
        id: this.reportForm.controls.single_customer_id.value,
        interval_start: new Date(this.pipeParse.transform(this.inputPeriodStart.nativeElement.value)).getTime() / 1000,
        interval_end: new Date(this.pipeParse.transform(this.inputPeriodEnd.nativeElement.value)).getTime() / 1000,
        sorting_sid: this.reportForm.controls.sequence_sid.value,
        locale: localStorage.getItem("language"),
      }
      this.reportingService.getReportRX(body,"customeractarendery").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }

    if(this.reportType.name == "r7"){
      let body = {
        interval_start: new Date(this.pipeParse.transform(this.inputPeriodStart.nativeElement.value)).getTime() / 1000,
        interval_end: new Date(this.pipeParse.transform(this.inputPeriodEnd.nativeElement.value)).getTime() / 1000,
        is_xml_export: this.reportForm.controls.is_export_xml.value,
        locale: localStorage.getItem("language"),
      }
      this.reportingService.getReportRX(body,"chambersupply").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }

    if(this.reportType.name == "r8"){
      let body = {
        interval_start: new Date(this.pipeParse.transform(this.inputPeriodStart.nativeElement.value)).getTime() / 1000,
        coworker_ids: this.reportForm.controls.signer_ids.value,
        isLiveOnly: this.isLiveOnly,
        isPassedOnly: this.isPassedOnly,
        locale: localStorage.getItem("language"),
      }
      this.reportingService.getReportRX(body,"signercontract").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }

    if(this.reportType.name == "r9"){
      let body = {
        customer_id: this.reportForm.controls.single_customer_id.value,
        workflow_id: this.reportForm.controls.single_workflow_id.value,
        locale: localStorage.getItem("language"),
      }
      this.reportingService.getReportRX(body,"balancesheet").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }

    if(this.reportType.name == "r10"){
      let body = {
        coworker_ids: this.reportForm.controls.coworker_ids.value,
        interval_start: new Date(this.pipeParse.transform(this.inputPeriodStart.nativeElement.value)).getTime() / 1000,
        locale: localStorage.getItem("language"),
      }
      this.reportingService.getReportRX(body,"coworkercustomer").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }

    if(this.reportType.name == "r11"){
      let body = {
        coworker_ids: this.reportForm.controls.coworker_ids.value,
  //      customer_ids: this.reportForm.controls.customer_ids.value,
        interval_start: new Date(this.pipeParse.transform(this.inputPeriodStart.nativeElement.value)).getTime() / 1000,
        interval_end: new Date(this.pipeParse.transform(this.inputPeriodEnd.nativeElement.value)).getTime() / 1000,
        locale: localStorage.getItem("language"),
        report: "1",
      }
      this.reportingService.getReportRX(body,"coworkerworkload").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }    
    
    if(this.reportType.name == "r12"){
      let body = {
        coworker_ids: this.reportForm.controls.coworker_ids.value,
        customer_ids: this.reportForm.controls.customer_ids.value,
        interval_start: new Date(this.pipeParse.transform(this.inputPeriodStart.nativeElement.value)).getTime() / 1000,
        interval_end: new Date(this.pipeParse.transform(this.inputPeriodEnd.nativeElement.value)).getTime() / 1000,
        locale: localStorage.getItem("language"),
        report: "2",
      }
      this.reportingService.getReportRX(body,"coworkerworkload").subscribe((response)=>{
        this.handleFileContent(response)
      })
    }      
    
  }
}
