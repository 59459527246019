<h2 mat-dialog-title>{{ contractForm.controls.id.value ? (editable ? ('common_title_contract_modification' | translate) : ('common_title_contract_view' | translate)) + ' (' + contractForm.controls.contract_code.value + ')' : ('common_title_acceptance_and_contract_recording' | translate) }}</h2>
<mat-dialog-content>
    <form [formGroup]="contractForm" [style.display]="'flex'" [style.justify-content]="'space-between'">
        <div [style.width]="'48%'">

            <app-select-search [selectionItems]="typeSelectionItems" [selectionType]="'singleSelect'"
                               [placeholder]="'companies_contract-type' | translate"
                               [selectCtrl]="contractForm.controls.contract_type_sid" [required]="true"
                               [disabled]="this.contractForm.get('contract_type_sid').disabled">
            </app-select-search>
            <app-select-search [selectionItems]="templateTypeSelectionItems" [selectionType]="'singleSelect'"
                               [placeholder]="'document_subtype-template-type' | translate"
                               [selectCtrl]="contractForm.controls.templateType" [required]="true">
            </app-select-search>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'companies_contract-cause' | translate }}</mat-label>
                <input matInput [matDatepicker]="picker" [imask]="{mask: 'dateformat-mask' | translate}" required="true" #input_signed_at>
                <!--                formControlName="signed_at"-->
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="contractForm.controls.signed_at.hasError('required')">
                    {{ 'error_message_contract_date_from_cannot_be_empty' | translate }}
                </mat-error>
            </mat-form-field>

            <app-select-search [selectionItems]="signatoryAuditorSelectionItems" [selectionType]="'singleSelect'"
                               [placeholder]="'companies_signing-auditor' | translate"
                               [selectCtrl]="contractForm.controls.signed_by_id" [required]="true"
                               [showClearButton]="true">
            </app-select-search>
            <app-select-search [editable]="editable" [chips]="true" [selectionItems]="rightToSignSelectionItems"
                               [selectionType]="'singleSelect'"
                               [placeholder]="'companies_procuration-right-signer' | translate"
                               [selectCtrl]="contractForm.controls.company_signer_id" [required]="true"
                               [showClearButton]="true">
            </app-select-search>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'common_title_audit_fee' | translate }}</mat-label>
                <input matInput type="number" formControlName="audit_fee" required>
                <mat-error *ngIf="contractForm.controls.audit_fee.hasError('required')">
                    {{ 'error_message_audit_fee_cannot_be_empty' | translate }}
                </mat-error>
            </mat-form-field>
            <app-select-search [selectionItems]="currencyList" [selectionType]="'singleSelect'"
                               [placeholder]="'workflows_foreign-currency' | translate"
                               [selectCtrl]="contractForm.controls.currency_iso_code" [required]="true"
                               [showClearButton]="true" [style.width]="'100%'">
            </app-select-search>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'contract_acceptance_validity-start' | translate }}</mat-label>
                <input matInput [matDatepicker]="pickerValidFrom" [imask]="{mask: 'dateformat-mask' | translate}"
                       #input_valid_from required>
                <!--                formControlName="valid_from"-->
                <mat-datepicker-toggle matSuffix [for]="pickerValidFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerValidFrom></mat-datepicker>
                <mat-error *ngIf="contractForm.controls.valid_from.hasError('required')">
                    {{ 'error_message_validity_start_cannot_be_empty' | translate }}
                </mat-error>
                <mat-error *ngIf="contractForm.controls.valid_from.hasError('minDate')">
                    {{ 'error_message_validity_start_must_be_after_contract_start' | translate }}
                </mat-error>
            </mat-form-field>



            <mat-form-field appearance="outline">
                <mat-label>{{ 'contract_acceptance_validity-end' | translate }}</mat-label>
                <input matInput [matDatepicker]="pickerValidTo" [imask]="{mask: 'dateformat-mask' | translate}"
                       required="true" #input_valid_to>
                <!--                formControlName="valid_to"-->
                <mat-datepicker-toggle matSuffix [for]="pickerValidTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerValidTo></mat-datepicker>
                <mat-error *ngIf="contractForm.controls.valid_to.hasError('required')">
                    {{ 'error_message_validity_end_cannot_be_empty' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'workflow_templates_business-year-start' | translate }}</mat-label>
                <input matInput [matDatepicker]="pickerBusinessYearFrom" [imask]="{mask: 'dateformat-mask' | translate}"
                       required="true" #input_businessYear_from>
                <!--                formControlName="business_year_start"-->
                <mat-datepicker-toggle matSuffix [for]="pickerBusinessYearFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerBusinessYearFrom></mat-datepicker>
                <mat-error *ngIf="contractForm.controls.business_year_start.hasError('required')">
                    {{ 'error_message_businessYear_start_cannot_be_empty' | translate }}
                </mat-error>

            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'workflow_templates_business-year-end' | translate }}</mat-label>
                <input matInput [matDatepicker]="pickerBusinessYearTo" [imask]="{mask: 'dateformat-mask' | translate}"
                       required="true" #input_businessYear_to>
                <!--                formControlName="business_year_end"-->
                <mat-datepicker-toggle matSuffix [for]="pickerBusinessYearTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerBusinessYearTo></mat-datepicker>
                <mat-error *ngIf="contractForm.controls.business_year_end.hasError('required')">
                    {{ 'error_message_businessYear_end_cannot_be_empty' | translate }}
                </mat-error>
            </mat-form-field>

            <div style="display: flex; justify-content: space-between; margin-bottom: 1.5rem;">
                <mat-checkbox formControlName="is_workflow_integrated" labelPosition="before" [style.width]="'100%'">
                    {{ 'companies_wf-integrated' | translate }}
                </mat-checkbox>
                <mat-checkbox formControlName="is_digitaly_signed" labelPosition="before" [style.width]="'100%'"
                              #is_esign>
                    {{ 'companies_electronic-signiture' | translate }}
                </mat-checkbox>
            </div>
            <app-select-search [selectionItems]="contractResultSelectionItems" [selectionType]="'singleSelect'"
                               [placeholder]="'companies_contract-process-result' | translate"
                               [selectCtrl]="contractForm.controls.contractResult | translate" [required]="true"
                               *ngIf="_contract && (_contract?.contract_status_sid != 'CON_STAT_PENDING' && !_contract?.is_digitaly_signed)"
                               [disabled]="this.contractForm.get('contractResult').disabled">
            </app-select-search>
        </div>
        <div [style.width]="'48%'" [style.margin-top]="'0.25rem'" [style.display]="'flex'"
             [style.flex-direction]="'column'" [style.justify-content]="'space-between'" [style.align-items]="'center'">
            <div [style.width]="'100%'" [style.margin-top]="'0.25rem'" [style.margin-bottom]="'.5rem'"
                 class="table-content">
                <div class="column">
                    <div class="table-header"
                         *ngIf="!_contract || _contract?.contract_status_sid == 'CON_STAT_PENDING'">
                        <div class="table-text" [style.width]="'45%'" [style.justify-content]="'flex-end'">
                            {{ 'companies_template' | translate }}
                        </div>
                        <div class="table-text" [style.width]="'25%'" [style.justify-content]="'flex-start'">
                            {{ 'companies_lang' | translate }}
                        </div>
                        <div class="table-text" [style.width]="'30%'"
                             [style.justify-content]="'flex-start'">{{ 'companies_options' | translate }}
                        </div>
                    </div>
                    <div class="table-header" *ngIf="_contract && _contract?.contract_status_sid != 'CON_STAT_PENDING'">
                        <div class="table-text" [style.width]="'40%'" [style.justify-content]="'flex-end'">
                            {{ 'companies_template' | translate }}
                        </div>
                        <div class="table-text" [style.width]="'20%'" [style.justify-content]="'flex-start'">
                            {{ 'companies_lang' | translate }}
                        </div>
                        <div class="table-text" [style.width]="'25%'"
                             [style.justify-content]="'flex-start'">{{ 'companies_options' | translate }}
                        </div>
                        <!--<div  class="table-text" [style.width]="'15%'" [style.justify-content]="'flex-start'"><!--{{'companies_document-status' | translate}}</div>  -->
                    </div>
                    <ng-container *ngIf="!_contract || _contract?.contract_status_sid == 'CON_STAT_PENDING'">
                        <div class="table-item" *ngFor="let template of templateList; let i = index">
                            <div class="table-text" [style.width]="'45%'" [style.justify-content]="'flex-end'"
                                 #tableRowDefault>
                                <ng-container *ngIf="templateList[i].template_code != templateList[i-1]?.template_code">
                                    {{ template.template_code | translate }}
                                </ng-container>
                            </div>
                            <div class="table-text" [style.width]="'25%'" [style.justify-content]="'flex-start'">
                                <mat-checkbox [checked]="template.template_code === 'TEMPLATE_CODE_ELFNY'"
                                              [disabled]="template.template_code === 'TEMPLATE_CODE_ELFNY'"
                                              (change)="selectTemplate(template)"
                                              [name]="template.template_code + '/' + template.subtemplate_id + '/' + template.locale"
                                              #templateCheckbox>
                                    {{ template.locale }}
                                </mat-checkbox>
                            </div>
                            <div class="table-text" [style.width]="'30%'" [style.justify-content]="'flex-start'">
                                <a mat-icon-button matTooltip="{{'common_title_view_template' | translate}}"
                                   (click)="openTemplate(template)">
                                    <mat-icon>find_in_page</mat-icon>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="_contract && _contract?.contract_status_sid != 'CON_STAT_PENDING'">
                        <div class="table-item" *ngFor="let template of _contract?.templates; let i = index">
                            <div class="table-text" [style.width]="'40%'" [style.justify-content]="'flex-end'"
                                 #tableRowModified>
                                {{ template.path.split("/")[1] | translate }}
                            </div>
                            <div class="table-text" [style.width]="'20%'" [style.justify-content]="'flex-start'">
                                {{ template.locale }}
                            </div>
                            <div class="table-text" [style.width]="'25%'" [style.justify-content]="'flex-start'">
                                <a mat-icon-button matTooltip="{{'common_title_view_template' | translate}}"
                                   (click)="openTemplate(template)">
                                    <mat-icon>find_in_page</mat-icon>
                                </a>
                                <a *ngIf="template.is_esign" mat-icon-button matTooltip="Dokumentum feltöltése"
                                   (click)="uploadFile(template)">
                                    <mat-icon>cloud_done</mat-icon>
                                </a>
                                <a *ngIf="template.is_esign" mat-icon-button matTooltip="E-aláírás indítása"
                                   (click)="startESign(template)">
                                    <mat-icon>gavel</mat-icon>
                                </a>
                            </div>
                            <div *ngIf="template.is_esign" class="table-text" [style.width]="'15%'"
                                 [style.justify-content]="'flex-center'">
                                <a *ngIf="template.esign_status == 'WAITING_TO_SIGN'">
                                    <mat-icon>pending_actions</mat-icon>
                                </a>
                                <a *ngIf="template.esign_status == 'SIGNED'">
                                    <mat-icon style="color:green">task</mat-icon>
                                </a>
                                <a *ngIf="template.esign_status == 'REFUSED'">
                                    <mat-icon style="color:red">do_not_disturb_on</mat-icon>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="table-content" [style.width]="'100%'" [style.margin-top]="'0.5rem'">
                <div class="column">
                    <div class="table-header">
                        <div class="table-text" [style.width]="'25%'" [style.justify-content]="'flex-start'">
                            {{ 'companies_template' | translate }}
                        </div>
                        <div class="table-text" [style.width]="'33%'" [style.justify-content]="'flex-start'">
                            Megnevezés
                        </div>
                        <div class="table-text" [style.width]="'40%'" [style.justify-content]="'flex-start'">
                            Tartalom
                        </div>
                    </div>
                    <ng-container formArrayName="usertext">
                        <ng-container *ngFor="let field of this.getUserTexts().controls; let i = index">
                            <div [style.height]="'7rem'" class="table-item" [formGroupName]="i">
                                <div class="table-text" [style.width]="'25%'" [style.justify-content]="'flex-start'">
                                    {{ field.value.template_code | translate }}
                                </div>
                                <div class="table-text" [style.width]="'33%'" [style.justify-content]="'flex-start'">
                                    {{ field.value.usertext_code | translate }}
                                </div>
                                <div class="table-text" [style.width]="'40%'" [style.justify-content]="'flex-start'">
                                    <mat-form-field appearance="outline">
                                        <textarea matInput formControlName="content"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="validationErrors.length > 0">
            <p *ngFor="let error of validationErrors">{{ error }}</p>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="reject()" class="negativeButton">{{ 'companies_cancel' | translate }}</button>
    <button mat-button (click)="saveContract(true)" class="neutralButton" [disabled]="!contractForm.valid"
            *ngIf="!_contract">{{ 'companies_leave-append' | translate }}
    </button>
    <!--[ngClass]="this.templateSelected ? 'positiveButton' : 'warningButton'"-->
    <button mat-button
            class="positiveButton"
            (click)="saveContract(false)"
            matTooltip="{{'no-template-selected' | translate}}"
            [matTooltipDisabled]="this.templateSelected"
            [disabled]="!contractForm.valid || !contractForm.dirty"
            *ngIf="editable || !_contract">
        {{ 'companies_record' | translate }}
    </button>
<!--    <button mat-button (click)="checkForm()" class="neutralButton" >Check</button>-->

</mat-dialog-actions>
