// tslint:disable-next-line:class-name
export class environment {
  //static serverEndpoint = 'https://adf34ab2-9a04-4e08-8e3e-e6810d6b8b15.mock.pstmn.io';
  static serverEndpoint = 'http://10.15.0.220';
  //static serverEndpoint = 'http://192.168.200.2:80';
  //static serverEndpoint = 'http://92.119.123.44:4000'
  static production = false;
  static serverMockEndpoint = 'https://adf34ab2-9a04-4e08-8e3e-e6810d6b8b15.mock.pstmn.io';
  static watsonEndpoint = 'https://integrations.us-south.assistant.watson.appdomain.cloud/public/message/cf88e7db-7f65-4e38-8fee-fe0abd8791b0?version=2020-09-24';
//  static watsonEndpoint = 'https://gateway.watsonplatform.net/assistant/api/v2/assistants/cd5e3659-8d8f-41d7-9f7a-89a0b7e88918/sessions';
}
