<h2 mat-dialog-title>{{(workflowBaseDataForm.controls.id.value ? ('workflow_templates_workflow-template-generic-data-modification' | translate) : ('workflow_templates_workflow-template-generic-data' | translate))}}</h2>
<!--<h3> {{ selectedCompany }}</h3>-->
<mat-dialog-content>
    <form [formGroup]="workflowBaseDataForm">
        <app-select-search [selectionItems]="customerSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_cleint' | translate" [selectCtrl]="workflowBaseDataForm.controls.customer" [required]="true">
        </app-select-search>
        <app-select-search [selectionItems]="contractSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_contract' | translate" [selectCtrl]="workflowBaseDataForm.controls.contract_id" [required]="true">
        </app-select-search>
        <mat-form-field appearance="outline">
            <mat-label>{{'workflow_templates_business-year-start' | translate}}</mat-label>
            <!--input matInput [type]="'date'" formControlName="start_of_business_year" required -->
            <input matInput [disabled]="CredentialsService.role != 'user'" [required]="CredentialsService.role == 'user'" [matDatepicker]="pickerStartBusinessYear" [imask]="{mask: 'dateformat-mask' | translate}" #input_start_business_year>
            <mat-datepicker-toggle matSuffix [for]="pickerStartBusinessYear"></mat-datepicker-toggle>
            <mat-datepicker #pickerStartBusinessYear></mat-datepicker>
            <mat-error *ngIf="workflowBaseDataForm.controls.start_of_business_year.hasError('required')">
                {{'error_message_business_year_start_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'workflow_templates_business-year-end' | translate}}</mat-label>
            <input matInput [disabled]="CredentialsService.role != 'user'" [required]="CredentialsService.role == 'user'" [matDatepicker]="pickerEndBusinessYear" [imask]="{mask: 'dateformat-mask' | translate}" #input_end_business_year>
            <mat-datepicker-toggle matSuffix [for]="pickerEndBusinessYear"></mat-datepicker-toggle>
            <mat-datepicker #pickerEndBusinessYear></mat-datepicker>
            <!--input matInput [type]="'date'" formControlName="end_of_business_year" required -->
            <mat-error *ngIf="workflowBaseDataForm.controls.end_of_business_year.hasError('required')">
                {{'error_message_business_year_end_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'workflow_templates_label' | translate}}</mat-label>
            <input matInput formControlName="caption" required>
            <mat-error *ngIf="workflowBaseDataForm.controls.caption.hasError('required')">
                {{'error_message_label_cannot_be_empty' | translate}}
            </mat-error>
        </mat-form-field>
          <app-select-search [disabled]="CredentialsService.role != 'user'" [required]="CredentialsService.role == 'user'" [selectionItems]="reportTypeSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'Beszámoló formája'" [selectCtrl]="workflowBaseDataForm.controls.report_type_sid">
          </app-select-search>
          <mat-form-field appearance="outline">
            <mat-label>{{'workflow_templates_inspection-close-date' | translate}}</mat-label>
            <input [min]='minDate' matInput [disabled]="CredentialsService.role != 'user'" [matDatepicker]="auditClosingDate" [imask]="{mask: 'dateformat-mask' | translate}" #input_audit_closing_date required>
            <mat-datepicker-toggle matSuffix [for]="auditClosingDate"></mat-datepicker-toggle>
            <mat-datepicker #auditClosingDate></mat-datepicker>
            <!--input matInput [type]="'date'" formControlName="audit_closing_date" required [min]="minValidFromClosing"-->
            <mat-error *ngIf="workflowBaseDataForm.controls.audit_closing_date.hasError('required')">
                {{'error_message_inspection_close_date_cannot_be_empty' | translate}}
              </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'workflow_templates_audit-report-issue-date' | translate}}</mat-label>
            <input [min]='minDate' matInput [disabled]="CredentialsService.role != 'user'" [matDatepicker]="auditReportDate" [imask]="{mask: 'dateformat-mask' | translate}" #input_audit_report_date required>
            <mat-datepicker-toggle matSuffix [for]="auditReportDate"></mat-datepicker-toggle>
            <mat-datepicker #auditReportDate></mat-datepicker>
            <!--input matInput [type]="'date'" formControlName="audit_report_date" required [min]="minValidFromReport" -->
            <mat-error *ngIf="workflowBaseDataForm.controls.audit_report_date.hasError('required')">
                {{'error_message_audit_report_issue_date_cannot_be_empty' | translate}}
              </mat-error>
            <mat-error *ngIf="workflowBaseDataForm.controls.audit_report_date.hasError('minDate')">
                {{'error_message_audit_report_issue_date_cannot_be_before_inspection_close_date' | translate}}
            </mat-error>
        </mat-form-field>
        <!--<app-select-search [selectionItems]="strictAssignmentSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_audit-team-setup-controll' | translate" [selectCtrl]="workflowBaseDataForm.controls.is_strict_assignment_control" [required]="true">
        </app-select-search>-->
<!--        <app-select-search [selectionItems]="strictClosureSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_mandatory-close-controll' | translate" [selectCtrl]="workflowBaseDataForm.controls.is_strict_closure_control" [required]="true">-->
<!--        </app-select-search>-->
                
        <app-select-search [selectionItems]="coworkersChangedSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_coworkers_changed' | translate" [selectCtrl]="workflowBaseDataForm.controls.is_coworkers_changed" [required]="true">
        </app-select-search>        
        
        <app-select-search [selectionItems]="statusSelectionItems" [selectionType]="'singleSelect'" [placeholder]="'workflow_templates_status' | translate" [selectCtrl]="workflowBaseDataForm.controls.workflow_status_sid">
        </app-select-search>
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button (click)="editBasicData()" class="positiveButton">{{'workflows_save' | translate}}</button>
    <button mat-button (click)="pending()" class="neutralButton" *ngIf="!workflowBaseDataForm.controls.id.value && CredentialsService.role == 'user'">{{'workflows_leave-pending' | translate}}</button>
    <button mat-button (click)="reject()" class="negativeButton">{{'workflows_reject' | translate}}</button>
</mat-dialog-actions>
