import {Component, ElementRef, OnInit, ViewChild, AfterContentInit, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {WorkflowTemplate} from 'src/app/data/workflow-template';
import {CredentialsService} from 'src/app/services/credentials.service';
import {OkDialogComponent} from '../../ok-dialog/ok-dialog.component';
import {WorkflowElement} from '../../../data/workflow-template';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CompanyService} from '../../../services/company.service';
import {UserWfsService} from '../../../services/user-wfs.service';
import {WfTemplateService} from '../../../services/wf-template.service';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter} from "@angular/material/core";
import {DateFormatPipe} from "../../../pipes/date-format.pipe";
import {DateParsePipe} from "../../../pipes/date-parse.pipe";
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-workflow-template-basic-editor',
    templateUrl: './workflow-template-basic-editor.component.html',
    styleUrls: ['./workflow-template-basic-editor.component.less']
})
export class WorkflowTemplateBasicEditorComponent implements AfterViewInit, AfterContentInit, OnInit {

    workflowBaseDataForm: FormGroup = this.formBuilder.group({
        id: null,
        customer: [null, Validators.required],
        contract_id: [null, Validators.required],
        start_of_business_year: [null],
        end_of_business_year: [null],
        caption: [null],
        audit_closing_date: null,
        audit_report_date: null,
        is_coworkers_changed: [false],
        is_strict_assignment_control: [false],
        is_strict_closure_control: [null, Validators.required],
        workflow_status_sid: null,
        report_type_sid: null,
        coworker_ids: [[]],
        workflow_elements: [[]]
    })

    _origPage: any;
    get origPage() { return this._origPage };
    set origPage(value: any) {
        this._origPage = value;
    }

    _selectedCompany: any;
    get selectedCompany() { return this._selectedCompany };
    set selectedCompany(value: any) {
        this._selectedCompany = value;
    }

    _reportTypeSelectionItems: any;
    get reportTypeSelectionItems() {
        return this._reportTypeSelectionItems
    };

    set reportTypeSelectionItems(value: any) {
        this._reportTypeSelectionItems = value;
    }

    _newWorkflowForm: any;
    get newWorkflowForm() {
        return this._newWorkflowForm
    };

    set newWorkflowForm(value: any) {
        this._newWorkflowForm = value;
    }

    _template: any;
    get template() {
        return this._template
    };

    set template(value: any) {
        this._template = value;
        this.workflowBaseDataForm.patchValue(value);
    }

    _customerSelectionItems: any;
    get customerSelectionItems() {
        return this._customerSelectionItems
    };

    set customerSelectionItems(value: any) {
        this._customerSelectionItems = value;
    }

    _contracts: any;
    get contracts() {
        return this._contracts
    };

    set contracts(value: any) {
        this._contracts = value;
    }

    _workflows: any;
    get workflows() {
        return this._workflows
    };

    set workflows(value: any) {
        this._workflows = value;
    }

    _templateWorkflowId: any = 0;
    get templateWorkflowId() {
        return this._templateWorkflowId
    };
    set templateWorkflowId(value: any) {
        this._templateWorkflowId = value;
    }


    contractSelectionItems = [];
    strictAssignmentSelectionItems = [{value: true, name: "1"}, {value: false, name: "0"}];
    strictClosureSelectionItems = [{value: true, name: "1"}, {value: false, name: "0"}];
    coworkersChangedSelectionItems = [{value: true, name: "1"}, {value: false, name: "0"}];
    statusSelectionItems = [];

    // minValidFromClosing = format(add(new Date(), {days: 1}), 'yyyy-MM-dd');;
    minValidFromReport: string;

    // _templateWorkflowId = 0;
    inheritedWorkflowId = 0;
    minDate: Date;

    wf2copy: any;

    CredentialsService: any = CredentialsService;

    translatableTexts: any[] = [
        {value: "error_message_error_creating_workflow_template", name: ""},
        {value: "error_message_error_editing_workflow_template", name: ""}
    ];

    @ViewChild("input_start_business_year") inputStartBusinessYear: ElementRef;
    @ViewChild("input_end_business_year") inputEndBusinessYear: ElementRef;
    @ViewChild("input_audit_closing_date") inputAuditClosingAt: ElementRef;
    @ViewChild("input_audit_report_date") inputAuditReportAt: ElementRef;

    constructor(private pipeParse: DateParsePipe, private pipeFormat: DateFormatPipe, private _adapter: DateAdapter<any>, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<WorkflowTemplateBasicEditorComponent>, private dialogService: MatDialog, private el: ElementRef, public http: HttpClient, private snackBar: MatSnackBar, private companyService: CompanyService, private service: UserWfsService, private wfTemplateService: WfTemplateService, private translateService: TranslateService) {
        dialogRef.disableClose = true;
        const currentYear = new Date().getFullYear();
        this.minDate = new Date()
    }

    ngAfterContentInit(): void {
        this._adapter.setLocale(localStorage.getItem("language") == "hu_HU" ? 'hu' : 'en');
    }

    ngAfterViewInit(): void {
        if (this._template) {
            if (this._template.start_of_business_year != null) this.inputStartBusinessYear.nativeElement.value = this.pipeFormat.transform(this._template.start_of_business_year * 1000);
            if (this._template.end_of_business_year != null) this.inputEndBusinessYear.nativeElement.value = this.pipeFormat.transform(this._template.end_of_business_year * 1000);
            if (this._template.audit_closing_date != null) this.inputAuditClosingAt.nativeElement.value = this.pipeFormat.transform(this._template.audit_closing_date * 1000);
            if (this._template.audit_report_date != null) this.inputAuditReportAt.nativeElement.value = this.pipeFormat.transform(this._template.audit_report_date * 1000);
        } else {
            this.inputStartBusinessYear.nativeElement.value = this.pipeFormat.transform(this.addYearToEpoch(this.contracts[0].business_year_start, true) * 1000);
            this.inputEndBusinessYear.nativeElement.value = this.pipeFormat.transform(this.addYearToEpoch(this.contracts[0].business_year_end, true) * 1000);
            // console.log("WF2:",this.wf2copy);
            // this.inputAuditClosingAt.nativeElement.value = this.pipeFormat.transform(this.addYearToEpoch(this.wf2copy.audit_closing_date, true) * 1000);
            // this.inputAuditReportAt.nativeElement.value = this.pipeFormat.transform(this.addYearToEpoch(this.wf2copy.audit_report_date, true) * 1000);

        }
    }

    listContracts(value) {
        this.companyService.listContracts({customer_id: value}).subscribe((response) => {
            let contractItems = [];
            this.contracts = response.payload.content;
            response.payload.content.forEach((contract) => {
                if (contract.is_active) {
                    contractItems.push({name: contract.contract_code, value: contract.id})
                }
            })
            this.contractSelectionItems = contractItems;
            this.workflowBaseDataForm.controls.contract_id.setValue(this.contracts[0].id);
        })
    }

    addYearToEpoch(epoch: number, convBack: boolean): any {

        // console.log("Prev epoch: ",epoch);
        // const currentDate = new Date();



        const inputDate = new Date(epoch * 1000);

        const newYear = inputDate.getFullYear() + 1;

        const month = inputDate.getMonth();
        const day = inputDate.getDate();

        const newDate = new Date(newYear, month + 1, 0);

        const validDay = day > newDate.getDate() ? newDate.getDate() : day;

        const finalDate = new Date(newYear, month, validDay);

        if (convBack) {
            let newEpoch = Math.floor(finalDate.getTime() / 1000);
            // console.log("New epoch: ", newEpoch);
            return newEpoch
        } else {
            return finalDate.toISOString().split('T')[0];
        }
    }

    fillContracts() {
        // console.log("Contr:", this.contracts);
        let contractItems = [];
        this.contracts.forEach((contract) => {
            if (contract.is_active) {
                contractItems.push({name: contract.contract_code, value: contract.id})
            }
        })
        this.contractSelectionItems = contractItems;
        this.workflowBaseDataForm.controls.contract_id.setValue(this.contracts[0].id);
    }
    ngOnInit(): void {
        // console.log(this.minDate);
        //
        // console.log("contracts: ", this.contracts);
        // console.log("workflows: ", this.workflows);

        if (CredentialsService.role == 'owner' || CredentialsService.role == 'admin') {
            this.workflowBaseDataForm.get("customer").disable();
            this.workflowBaseDataForm.get("contract_id").disable();
            this.workflowBaseDataForm.get("workflow_status_sid").disable();
            /*      this.workflowBaseDataForm.get("start_of_business_year").disable();
                  this.workflowBaseDataForm.get("end_of_business_year").disable();
                  this.workflowBaseDataForm.get("audit_closing_date").disable();
                  this.workflowBaseDataForm.get("audit_report_date").disable();
                  this.inputStartBusinessYear.nativeElement.disabled = true;
                  this.inputEndBusinessYear.nativeElement.disabled = true;
                  this.inputAuditClosingAt.nativeElement.disabled = true;
                  this.inputAuditReportAt.nativeElement.disabled = true;*/
        }
        if (CredentialsService.role == 'user') {


            this.service.getWfStatuses().subscribe((response) => {
                let statusItems = [];
                response.payload.forEach((status) => {
                    if (status.is_active) {
                        statusItems.push({name: status.sid, value: status.sid})
                    }
                })
                this.statusSelectionItems = statusItems;
            })

            // ToDo üzleti év és egyéb adatok feltöltése

            if (this.newWorkflowForm) {


                // console.log("Mode:");
                // console.log(this.newWorkflowForm.controls.base.value);

                this.workflowBaseDataForm.controls.customer.setValue(this.selectedCompany.value);
                // this.listContracts(this.selectedCompany.value)

                // console.log("Contracts:");
                // console.log(this.contracts);

                this.workflowBaseDataForm.controls.customer.valueChanges.subscribe((value) => {
                    this.listContracts(value)
                })

                this.fillContracts();

                // console.log("tmpl:", this.templateWorkflowId);

                // this.workflowBaseDataForm.controls.contract_id.setValue(this.contracts[0].id);
                this.workflowBaseDataForm.get("workflow_status_sid").disable();

                switch (this.newWorkflowForm.controls.base.value) {
                    case 'previousDate':
                        // console.log("Previous date mode");
                        // console.log(this.workflows);
                        let wfid=this.templateWorkflowId;
                        if (wfid == 0) { wfid = this.workflows[0].id;}

                        //this.newWorkflowForm.controls.dateWorkflow.value
                        this.service.getWf(wfid).subscribe((response) => {
                            // console.log(response)
                            this.workflowBaseDataForm.patchValue(response.payload)
                            this.workflowBaseDataForm.get("id").setValue(null);
                            // this.workflowBaseDataForm.get("start_of_business_year").setValue(new Date(new Date(response.payload.start_of_business_year * 1000).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0]);
                            // this.workflowBaseDataForm.get("end_of_business_year").setValue(new Date(new Date(response.payload.end_of_business_year * 1000).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0]);
                            this.wf2copy = response.payload;
                            // if (response.payload.audit_closing_date) {
                            // this.workflowBaseDataForm.get("audit_closing_date").setValue(new Date(new Date(this.addYearToEpoch(this.wf2copy.audit_closing_date, true) * 1000).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0]);
                            // }
                            // console.log(this.addYearToEpoch(this.wf2copy.audit_closing_date, true) * 1000);
                            // if (response.payload.audit_report_date) {
                            //     this.workflowBaseDataForm.get("audit_report_date").setValue(new Date(new Date(response.payload.audit_report_date * 1000).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0]);
                            // }
                            this.inputAuditClosingAt.nativeElement.value = this.pipeFormat.transform(this.addYearToEpoch(this.wf2copy.audit_closing_date, true) * 1000);
                            this.inputAuditReportAt.nativeElement.value = this.pipeFormat.transform(this.addYearToEpoch(this.wf2copy.audit_report_date, true) * 1000);

                            // console.log("WF1",this.wf2copy);
                            this.inheritedWorkflowId = this.wf2copy.id;
                            this.templateWorkflowId = 0
                        })
                        break;
                    case 'template':
                        // console.log("Workflow template");
                        //this.newWorkflowForm.controls.workflow_elements.setValue()
                        this.wfTemplateService.getTemplate(this.newWorkflowForm.controls.templateWorkflow.value).then((response) => {
                            this.workflowBaseDataForm.get("workflow_elements").setValue(response.payload.workflow_elements);
                            this.workflowBaseDataForm.get("is_strict_assignment_control").setValue(response.payload.is_strict_assignment_control);
                            this.workflowBaseDataForm.get("is_strict_closure_control").setValue(response.payload.is_strict_closure_control);
                            this.templateWorkflowId = this.newWorkflowForm.controls.templateWorkflow.value;
                            //console.log(this.workflowBaseDataForm)
                        })
                        break;
                    case 'existingWorkflow':
                        // console.log("Existing workflow");
                        // this.service.getWf(this.newWorkflowForm.controls.workflow.value).subscribe((response) => {
                        //     console.log(response)
                        //     this.workflowBaseDataForm.patchValue(response.payload)
                        //     this.workflowBaseDataForm.get("id").setValue(null);
                        //     this.workflowBaseDataForm.get("start_of_business_year").setValue(new Date(new Date(response.payload.start_of_business_year * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0]);
                        //     this.workflowBaseDataForm.get("end_of_business_year").setValue(new Date(new Date(response.payload.end_of_business_year * 1000).getTime() - new Date().getTimezoneOffset()*60*1000).toISOString().split("T")[0]);
                        //     if (response.payload.audit_closing_date) {
                        //         this.workflowBaseDataForm.get("audit_closing_date").setValue(new Date(new Date(response.payload.audit_closing_date * 1000).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0]);
                        //     }
                        //     if (response.payload.audit_report_date) {
                        //         this.workflowBaseDataForm.get("audit_report_date").setValue(new Date(new Date(response.payload.audit_report_date * 1000).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0]);
                        //     }
                        //
                        //     this.inheritedWorkflowId = response.payload.id;
                        // })
                        // break;
                }
            } else {
                // console.log("Edit mode");
 //                this.fillContracts();
 // console.log(this.template);
 // console.log(this.contracts)
 //                console.log(this.template.contract_id);
                // this.workflowBaseDataForm.controls.contract_id.setValue(this.template.contract_id);
                //  this.workflowBaseDataForm.controls.contract_id.setValue(this.findById(this.contracts, this.template.contract_id));
                this.workflowBaseDataForm.controls.customer.setValue(this.findSelectionItem(this.customerSelectionItems, this.findById(this.contracts, this.workflowBaseDataForm.controls.contract_id.value)?.customer_id)?.value)

                this.workflowBaseDataForm.get("start_of_business_year").setValue(new Date(new Date(this.template.start_of_business_year * 1000).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0]);
                this.workflowBaseDataForm.get("end_of_business_year").setValue(new Date(new Date(this.template.end_of_business_year * 1000).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0]);
                if (this.template.audit_closing_date) {
                    this.workflowBaseDataForm.get("audit_closing_date").setValue(new Date(new Date(this.template.audit_closing_date * 1000).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0]);
                }
                if (this.template.audit_report_date) {
                    this.workflowBaseDataForm.get("audit_report_date").setValue(new Date(new Date(this.template.audit_report_date * 1000).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0]);
                }
            }

            // this.workflowBaseDataForm.controls.audit_closing_date.valueChanges.subscribe((value) => {
            //     this.minValidFromReport = format(add(new Date(value), {days: 1}), 'yyyy-MM-dd');
            //     if (new Date(this.workflowBaseDataForm.controls.audit_closing_date.value).getTime() >= new Date(this.workflowBaseDataForm.controls.audit_report_date.value).getTime()) {
            //         this.workflowBaseDataForm.controls.audit_report_date.setErrors({'minDate': true})
            //     } else {
            //         setTimeout(() => {
            //             this.workflowBaseDataForm.controls.audit_report_date.setErrors(null)
            //         })
            //     }
            // })

        }

        this.translateService.stream(['error_message_error_creating_workflow_template', 'error_message_error_editing_workflow_template']).subscribe((res: string) => {
            this.translatableTexts = [
                {
                    value: "error_message_error_creating_workflow_template",
                    name: res["error_message_error_creating_workflow_template"]
                },
                {
                    value: "error_message_error_editing_workflow_template",
                    name: res["error_message_error_editing_workflow_template"]
                }
            ];
        });

    }

    /*reject(){
      const dialog = this.dialogService.open(OkDialogComponent);
      dialog.componentInstance.title = 'Figyelem';
      dialog.componentInstance.description =
          'Elveti a módosításokat és kilép?';
      dialog.componentInstance.positiveButtonText = "Igen";
      dialog.componentInstance.negativeButtonText = "Nem";
      dialog.afterClosed().subscribe(response => {
        if (response) {
          this.dialogRef.close();
        }
      })
    }*/

    findById(selection, id) {
        return selection.find(item => item.id == id)
    }


    findSelectionItem(selection, value) {
        return selection.find(item => item.value == value);
    }

    editBasicData() {
        if (this.workflowBaseDataForm.valid) {
            if (CredentialsService.role == 'owner' || CredentialsService.role == 'admin') {
                if (this.workflowBaseDataForm.controls.id.value) {
                    this.modifyOwnerWfTemplate().then((response) => {
                        this.dialogRef.close(response);
                    });
                } else {
                    this.saveOwnerWfTemplate().then((response) => {
                        this.dialogRef.close(response);
                    });
                }
            } else if (CredentialsService.role == 'user') {
                let form = JSON.parse(JSON.stringify(this.workflowBaseDataForm.getRawValue()));

                form.start_of_business_year = new Date(this.pipeParse.transform(this.inputStartBusinessYear.nativeElement.value)).getTime() / 1000;
                form.end_of_business_year = new Date(this.pipeParse.transform(this.inputEndBusinessYear.nativeElement.value)).getTime() / 1000;
                form.audit_closing_date = new Date(this.pipeParse.transform(this.inputAuditClosingAt.nativeElement.value)).getTime() / 1000;
                form.audit_report_date = new Date(this.pipeParse.transform(this.inputAuditReportAt.nativeElement.value)).getTime() / 1000;

                /*
                form.start_of_business_year = new Date(this.workflowBaseDataForm.get("start_of_business_year").value).getTime() / 1000
                form.end_of_business_year = new Date(this.workflowBaseDataForm.get("end_of_business_year").value).getTime() / 1000
                form.audit_closing_date = new Date(this.workflowBaseDataForm.get("audit_closing_date").value).getTime() / 1000
                form.audit_report_date = new Date(this.workflowBaseDataForm.get("audit_report_date").value).getTime() / 1000
                */

                if (this.workflowBaseDataForm.controls.id.value) {
                    // console.log("1st case:", this.workflowBaseDataForm.controls.id.value);
                    this.modifyUserWfTemplate(form).then((response) => {
                        this.dialogRef.close(response);
                    });
                } else {
                    // console.log("2nd case:", this.workflowBaseDataForm.controls.id.value);
                    this.saveUserWfTemplate(form);
                    /*this.saveUserWfTemplate(form).subscribe((response) => {
                      this.dialogRef.close(response);
                    });*/
                }
            }

        } else {
            this.workflowBaseDataForm.markAllAsTouched();
            let firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
            this.scrollToFirstInvalidControl(firstInvalidControl);
        }
    }

    saveOwnerWfTemplate() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        this.workflowBaseDataForm.get("id").disable();

        return this.http.post<any>(environment.serverEndpoint + '/owner/wftemplate', this.workflowBaseDataForm.value)
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_workflow_template").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    modifyOwnerWfTemplate() {
        let wfBaseData = JSON.parse(JSON.stringify(this.workflowBaseDataForm.value))
        this.template.coworkers.forEach((coworker) => {
            wfBaseData.coworker_ids.push(coworker.id)
        })
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.put<any>(environment.serverEndpoint + '/owner/wftemplate', wfBaseData)
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_editing_workflow_template").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }

    saveUserWfTemplate(form) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        delete form.id;

        // console.log(this.inputStartBusinessYear.nativeElement.value);
        //if(this.inputStartBusinessYear.nativeElement.value == null)
        if (!this.inputStartBusinessYear.nativeElement.value) {
            this.inputStartBusinessYear.nativeElement.focus();
            return;
        }
        if (!this.inputEndBusinessYear.nativeElement.value) {
            this.inputEndBusinessYear.nativeElement.focus();
            return;
        }
        if (!this.inputAuditClosingAt.nativeElement.value) {
            this.inputAuditClosingAt.nativeElement.focus();
            return;
        }
        if (!this.inputAuditReportAt.nativeElement.value) {
            this.inputAuditReportAt.nativeElement.focus();
            return;
        }

        /*
        form.start_of_business_year = null;
        form.end_of_business_year = null;
        form.audit_report_date = null;
        form.audit_closing_date = null;
        form.signed_at = null;
        */

        // console.log(form);

        form.workflow_status_sid = 'WF_STAT_PENDING';
        // console.log("templateworkId:", this.templateWorkflowId);
        // console.log("inheritedWorkflowId:", this.inheritedWorkflowId);
        this.wfTemplateService.saveWF(form).subscribe((response) => {
            if (this.templateWorkflowId != 0) {
                if (response) {
                    if (response.type == 'OK') {
                        this.service.postTemplateContentCopy(response.payload.id, this.templateWorkflowId).subscribe((resp) => this.dialogRef.close(response));
                    }
                }
            } else if (this.inheritedWorkflowId != 0) {
                if (response) {
                    if (response.type == 'OK') {
                        this.service.postWorkflowContentCopy(response.payload.id, this.inheritedWorkflowId).subscribe((resp) => this.dialogRef.close(response));
                    }
                }
            } else {
                this.dialogRef.close(response);
            }
        })
        /*
        return this.http.post<any>(environment.serverEndpoint + '/user/wf', form)
        .toPromise()
        .catch(error => {
          this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_creating_workflow_template").name, null, {panelClass: 'error-snackbar'});
          console.log(error);
          return Promise.reject(error);
        })*/
        /*.then((response) => {
             if(this.templateWorkflowId!=0) {
               if (response) {
                 if (response.type == 'OK') {
                   this.service.postTemplateContentCopy(response.payload.id, this.templateWorkflowId);
                 }
               }
             }
           })*/
    }

    modifyUserWfTemplate(body) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.put<any>(environment.serverEndpoint + '/user/wf', body)
            .toPromise()
            .catch(error => {
                this.snackBar.open(this.translatableTexts.find(element => element.value === "error_message_error_editing_workflow_template").name, null, {panelClass: 'error-snackbar'});
                console.log(error);
                return Promise.reject(error);
            })
    }


    private scrollToFirstInvalidControl(firstInvalidControl) {
        if (firstInvalidControl) {
            firstInvalidControl.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }

    reject() {
        if (this.workflowBaseDataForm.dirty) {
            const dialog = this.dialogService.open(OkDialogComponent);
            this.translateService.get('companies_reject-modification-exit').subscribe((value) => dialog.componentInstance.title = value)
            this.translateService.get('1').subscribe((value) => dialog.componentInstance.positiveButtonText = value)
            this.translateService.get('0').subscribe((value) => dialog.componentInstance.negativeButtonText = value)
            dialog.afterClosed().subscribe(response => {
                if (response) {
                    this.dialogRef.close();
                }
            })
        } else {
            this.dialogRef.close();
        }
    }


}
