import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MatChip, MatChipInputEvent, MatChipList } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EventEmitter } from 'events';

export interface SelectionItem {
  value: {id: string, is_primary?: boolean | number};
  name: string;
}

export class Signer{
  id: string;
}

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.less']
})
export class SelectSearchComponent implements OnInit {

  @Input() appearanceStyle: string = "outline";

  @Input() chips: boolean = false;

  selectedChips: SelectionItem[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];

  //@Input() selectionItems: SelectionItem[];
  private _selectionItems = new BehaviorSubject<SelectionItem[]>([]);

  // change data to use getter and setter
  @Input()
  set selectionItems(value) {
    // set the latest value for _data BehaviorSubject
    this._selectionItems.next(value);
  };

  get selectionItems() {
    // get the latest value from _data BehaviorSubject
    return this._selectionItems.getValue();
  }

  @Input() selectionType: string; //singleSelect, multiSelect
  @Input() placeholder: string;
  @Input() placeholderLabel: string = "Keresés";
  @Input() noEntriesFoundLabel:string = "Nincs találat";
  @Input() selectCtrl: FormControl = new FormControl([]);
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholderError: string;
  @Input() showClearButton: boolean = false;
  @Input() editable: boolean = true;
  

  @Input() primarySigner: any;

  public selectFilterCtrl: FormControl = new FormControl();

  signerIds: Signer[] = [];
  chipsSelectionItems: any[] = [];


  @ViewChild('selection', { static: true }) selection: MatSelect;
  @ViewChild('chipList', { static: true}) chipList: MatChip;

  public filteredSelection: ReplaySubject<SelectionItem[]> = new ReplaySubject<SelectionItem[]>(1);

  protected _onDestroy = new Subject<void>();
  
  translatableTexts: any[] = [
    {value: "common_title_search", name: ""},
    {value: "common_message_no_results", name: ""}
  ];
  onChange!: (value: string[]) => void;

  

  constructor(private translateService: TranslateService) { }

  ngOnInit() {


    if(this.chips){
      let newSelectionItems = [];
      this.selectionItems.forEach((item:any) => {
        let newItem = {name:item.name, value: {id:item.value, is_primary: item.value.is_primary}};
        newSelectionItems.push(newItem);
      })
      this.selectionItems = newSelectionItems;
      this.filteredSelection.next(newSelectionItems);
      let values = [];
      if(this.selectCtrl.value){
      this.selectCtrl.value.forEach(signer => {
        // console.log(signer);
        let obj:any = this.selectionItems.find(x => x.value.id == signer.id);
        obj.value.is_primary = signer.is_primary;
        if(obj){
          // console.log(obj.value);
          if(obj.value.is_primary === 1){
            // console.log(obj);
            obj.value.is_primary = true; 
          }
          // console.log(obj);
          this.selectedChips.push(obj);      
          values.push(obj.value);
        }else{
          let obj = this.selectionItems.find(x => x.value == signer.id);
          // console.log(obj);
        }
      });
      // console.log(this.selectedChips);
      this.selectCtrl.setValue(values);
      // console.log(this.selectCtrl.value, this.selectedChips);
      if(this.selectedChips.length == 1){
        this.selectedChips.forEach(chip=> {
          chip.value.is_primary = true;
        })
      }
      this.selectCtrl.updateValueAndValidity();
      }
    }
    

    this._selectionItems.subscribe(x => {
      this.filteredSelection.next(this.selectionItems?.slice());
      //this.filterSelection();
    });

    

    this.filteredSelection.next(this.selectionItems?.slice());

    this.selectFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        console.log("change");
        this.filterSelection();
      });

     


    this.translateService.stream(['common_title_search', 'common_message_no_results']).subscribe((res: string) => {
      this.translatableTexts = [
        {value: "common_title_search", name: res["common_title_search"]},
        {value: "common_message_no_results", name: res["common_message_no_results"]}
      ];
    });

    this.placeholderLabel = this.translatableTexts.find(element => element.value === "common_title_search").name
    this.noEntriesFoundLabel = this.translatableTexts.find(element => element.value === "common_message_no_results").name

  }



  ngAfterViewInit() {
    
  }

  ngAfterViewChecked(){
    document.querySelectorAll(".mat-option")?.forEach((matOption) => {
      if (matOption.classList.contains("mat-active")){
        matOption.classList.remove("mat-active");
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  selectAsPrimary(chip){
    this.selectedChips.forEach(chips => {
      console.log(chips);
      chips.value.is_primary = false;
    });
    chip.value.is_primary = true;
    this.selectCtrl.markAsDirty();
  }

  selectChips(event){
    if(event.source.selected){
      let obj = this.selectionItems.find(x => x.value == event.source.value);
      if(!this.selectedChips.includes(obj) || this.selectedChips.length === 0){
        this.selectedChips.push(obj);
        if(this.selectedChips.length === 1){
          this.selectedChips.forEach(chip=> {
            chip.value.is_primary = true;
          })
        }
      }
    }
    if(!event.source.selected){
      this.removeChip(event.source.value, true);
    }
    
  }

  removeChip(value, deselect:boolean){
    if(deselect){
      let obj = this.selectionItems.find(x => x.value == value);
      if(this.selectedChips.includes(obj)){
        let index = this.selectedChips.indexOf(obj);
        if(index > -1){
          this.selectedChips.splice(index, 1);
        }
      }
    }
    this.selectCtrl.markAsDirty();
    let index = this.selectedChips.indexOf(value);
    if(index > -1){
      this.selectedChips.splice(index, 1);
      let values = [];
      this.selectedChips.forEach(x => {
        values.push(x.value);
      })
      if(this.selectedChips.length === 1){
        this.selectedChips.forEach(chip=>{
          chip.value.is_primary = true;
        })
        values = this.selectedChips;
      }
      this.selectCtrl.setValue(values);
    }
  }


  protected filterSelection() {
    if (!this.selectionItems) {
      return;
    }

    let search = this.selectFilterCtrl.value;
    if (!search) {
      this.filteredSelection.next(this.selectionItems.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredSelection.next(
      this.selectionItems.filter(item => item.name.toLowerCase().indexOf(search) > -1)
    );
  }

  clearSelection(event){
    this.selectCtrl.setValue(null);
    this.selectedChips = [];
    event.stopPropagation();
  }

}
