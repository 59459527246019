<h2 mat-dialog-title>{{'file_system_file-upload' | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="fileForm">
        <div class="row">
            <div class="col-12 ">
                <mat-checkbox formControlName="state">{{'common_title_independent' | translate}}</mat-checkbox>
            </div>
        </div>
        <div style="width: 500px">
            <app-file-upload formControlName="file"></app-file-upload>
        </div>
        
    </form>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'space-around'">
    <button mat-button class="positiveButton" (click)="saveIndependentFile()">{{'button_title_save_file' | translate}}</button>
    <button mat-button class="negativeButton" (click)="closeIFdialog()">{{'button_title_cancel' | translate}}</button>
</mat-dialog-actions>
